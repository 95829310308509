import {
  getAppUsersByCustomer,
  newAppUser,
  updateAppUser,
  deleteAppUser,
  changeAppUserState,
  listLinkedAppUserPolicy
} from '@/api/api.js';

// --------------------- STATE --------------------------

export const state = {
  headers: [
    { text: 'Username', value: 'username', align: 'start' },
    { text: 'Name', value: '_name' },
    { text: 'Surname', value: 'surname' },
    { text: 'Email', value: 'email' },
    { text: 'Phone', value: 'phone' },
    { text: 'Language', value: 'language' },
    { text: 'Map Style', value: 'user_map_style' },
    { text: 'State', value: '_state' },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  aUsers: [],
  serviceAUser: [],
  isModifingAppUser: false,
  isModifingNotCreatedAppUsers: false,
  selectablePolicies: [],
  setSelectedPolicies: [],
  listLinkedAppUserPolicy: [],
  cuDialogAU: false,
  storeAppUserForm: {},
  crm_id: null,
  appUserDisabled: false,
  createAppUser: []
};

// ------------------------------ MUTATIONS -----------------------------

export const mutations = {
  SWITCH_A_USER_STATE(state, index) {
    state.aUsers[index]._state = !state.aUsers[index]._state;
  },
  GET_APP_USERS(state, aUsersArray) {
    state.aUsers = aUsersArray;
  },
  NEW_APP_USER(state, userToAdd) {
    userToAdd.data.createAppUser.created.forEach(user => state.aUsers.push(user));
    state.createAppUser = userToAdd.data.createAppUser;

    if (userToAdd.data.createAppUser.not_created == undefined || userToAdd.data.createAppUser.not_created.length < 1) {
      state.createAppUser.not_created = undefined;
    }
  },
  UPDATE_APP_USER(state, user) {
    state.aUsers.forEach(element => {
      if (element.app_user_id == user.data.updateAppUser.app_user_id) {
        element._name = user.data.updateAppUser._name;
        element.surname = user.data.updateAppUser.surname;
        element.email = user.data.updateAppUser.email;
        element.phone = user.data.updateAppUser.phone;
        element.language = user.data.updateAppUser.language;
        element.user_map_style = user.data.updateAppUser.user_map_style;
      }
    });
  },
  DELETE_APP_USER(state, delete_app_user_id) {
    state.aUsers.forEach(element => {
      if (element.app_user_id == delete_app_user_id) {
        const index = state.aUsers.indexOf(element);
        state.aUsers.splice(index, 1);
      }
    });
  },
  SET_SELECTABLE_POLICIES(state, data) {
    state.selectablePolicies = data;
  },
  SET_SELECT_POLICIES_MUTATION(state, data) {
    state.listLinkedAppUserPolicy = data;
    state.aUsers.map(element => {
      data.forEach(e => {
        if (e.app_user_id == element.app_user_id) {
          element[e.service_id] = e.policy_name;
          element.policy_name = e.policy_name;
          element.service_id = e.service_id;
          element.service_name = e.service_name;
          element.service_description = e.description;
          element.service_name = e.service_name;
          element.service_type = e.service_type;

          return element;
        }
      });
    });
  },
  STORE_DIALOG(state, cuDialogAU) {
    state.cuDialogAU = cuDialogAU;
  },
  SWITCH_IS_MODIFING_APP_USER(state, data) {
    state.isModifingAppUser = data;
  },
  SWITCH_IS_MODIFING_NOT_CREATED_APP_USERS(state, data) {
    state.isModifingNotCreatedAppUsers = data;
  },
  SET_NOT_CREATED_APP_USERS(state, data) {
    state.createAppUser.not_created = data;
  },
  SET_CUSTOM_ID(state, data) {
    state.crm_id = data;
  },
  SET_DISABLE(state, data) {
    state.appUserDisabled = data;
  }
};

// ---------------------- GETTERS ---------------------
export const getters = {
  getHeaders: () => {
    const new_headers = [...state.headers];
    new_headers.pop();

    return new_headers;
  }
};

// ------------------------- ACTIONS -----------------------------

export const actions = {
  async setSelectPolicies({ state, dispatch, commit }) {
    const list_app_user_id = [
      ...state.aUsers.map(e => {
        return { app_user_id: e.app_user_id };
      })
    ];

    try {
      const resp = await listLinkedAppUserPolicy(list_app_user_id);
      commit('SET_SELECT_POLICIES_MUTATION', resp.data.listLinkedAppUserPolicy);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'setSelectPolicies',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  storeSwitchAUState({ state, commit }, selected) {
    for (let i = 0;i < selected.length;i++) {
      for (let j = 0;j < state.aUsers.length;j++) {
        if (state.aUsers[j].app_user_id === selected[i].app_user_id) {
          commit('SWITCH_A_USER_STATE', j);
        }
      }
    }
  },

  async switchAUState({ state, dispatch }, selected) {
    if (selected.length > 0) {
      for (let i = 0;i < selected.length;i++) {
        for (let j = 0;j < state.aUsers.length;j++) {
          if (state.aUsers[j].app_user_id === selected[i].app_user_id) {
            try {
              await changeAppUserState(
                state.aUsers[j].app_user_id,
                state.aUsers[j].username,
                !state.aUsers[j]._state,
                state.aUsers[j].cognito_user_created,
                state.aUsers[j]._name,
                state.aUsers[j].email
              );
            } catch (err) {
              dispatch(
                'storeToastMessage',
                {
                  text: 'switchAUState',
                  errObj: err,
                  type: 'danger'
                },
                { root: true }
              );
            }
          }
        }
      }

      dispatch('storeSwitchAUState', selected);
      dispatch(
        'storeToastMessage',
        {
          text: 'User state switched',
          type: 'success'
        },
        { root: true }
      );
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Please select one or more APP users',
          type: 'warning'
        },
        { root: true }
      );
    }
  },

  async storeAppUsers({ commit, dispatch }, crm_id) {
    try {
      const app_users = await getAppUsersByCustomer(crm_id);
      commit('GET_APP_USERS', app_users.data.listAppUsersByCustomer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAppUsers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewAppUser({ rootState, dispatch, commit }, appUsers) {
    try {
      appUsers.map(user => user.customer_id = rootState.customerCRMID.crm_id);
      const usersToAdd = await newAppUser(appUsers);
      dispatch(
        'storeToastMessage',
        {
          text: 'App users added',
          type: 'success'
        },
        { root: true }
      );
      commit('NEW_APP_USER', usersToAdd);
    } catch (err) {
      state.createAppUser.not_created = appUsers;
      dispatch(
        'storeToastMessage',
        {
          text: 'No users added',
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeUpdatedAppUser({ dispatch, commit }, app_user) {
    try {
      const userToUpdate = await updateAppUser(
        app_user.app_user_id,
        app_user._name,
        app_user.surname,
        app_user.email,
        app_user.phone,
        app_user.language,
        app_user.user_map_style
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'app user updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_APP_USER', userToUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedAppUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteTableAppUser({ dispatch, commit }, app_user) {
    try {
      const userToDelete = await deleteAppUser(app_user.app_user_id, app_user.username, app_user.cognito_user_created);
      dispatch(
        'storeToastMessage',
        {
          text: 'app user deleted',
          type: 'success'
        },
        { root: true }
      );
      commit('DELETE_APP_USER', userToDelete.data.deleteAppUser.username);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTableAppUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  dialogAction({ commit }, cuDialogAU) {
    commit('STORE_DIALOG', cuDialogAU);
  },
  storeIsModifingAppUser({ commit }, data) {
    commit('SWITCH_IS_MODIFING_APP_USER', data);
  },
  storeIsModifingNotCreatedAppUsers({ commit }, data) {
    commit('SWITCH_IS_MODIFING_NOT_CREATED_APP_USERS', data);
  },
  setNotCreatedAppUsers({ commit }, data) {
    commit('SET_NOT_CREATED_APP_USERS', data);
  },
  storeCustomerID({ commit }, data) {
    commit('SET_CUSTOM_ID', data);
  },
  storeDisable({ commit }, data) {
    commit('SET_DISABLE', data);
  }
};
