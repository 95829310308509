<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-flex
    offset-0
    class="pt-3"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-sheet
        class="v-sheet--offset mx-auto mt-4"
        color="success"
        elevation="12"
        max-width="calc(100% - 32px)"
      >
        <v-card
          dark
          color="color_green"
        >
          <v-card-title class="card-title v-card-scroll d-flex justify-space-between">
            {{ $route.path === '/ticketing-list' ? 'Complete request list' : 'Request list' }}

            <div
              v-if="$route.path === '/ticketing-list'"
              class="d-flex flex-wrap justify-end"
              style="width: 350px;"
            >
              <v-btn
                v-if="isResult"
                outlined
                light
                icon
                color="white"
                class="me-5"
                @click="refreshSearch()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                v-if="ticketing.ticketingRequestListFormatted.length"
                outlined
                light
                color="white"
                @click="toggleSearchFilters()"
              >
                <v-icon class="me-3">
                  mdi-arrow-collapse-left
                </v-icon>
                {{ isResult ? 'Back to search filters' : 'Back to results' }}
              </v-btn>
              <v-switch
                v-if="isResult"
                v-model="isMap"
                prepend-icon="mdi-list-box-outline"
                append-icon="mdi-map-outline"
                hide-details
              />
            </div>
          </v-card-title>
        </v-card>
      </v-sheet>
      <br>
      <div v-if="isResult">
        <ticketing-map-request-list
          v-if="isMap"
          :requests="ticketing.ticketingRequestListFormatted || []"
          @show-requests="selectFromMap($event)"
        />
        <div v-else>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="rangeMenu"
                  v-model="rangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="rangeDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="rangeDateText"
                      multiple
                      chips
                      small-chips
                      label="Range date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      class="shrink"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="rangeDate"
                    no-title
                    range
                    scrollable
                    :min="rangeDate[0]"
                    :max="moment().format('YYYY-MM-DD')"
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="closeDateRange()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="rangeDate.length < 2"
                      @click="changeFilterDate()"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="button-margin"
                  small
                  @click="exportExcelFiltered()"
                >
                  Export table
                  <v-icon
                    class="ma-1"
                    color="green"
                  >
                    mdi-file-export
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              id="requests-table"
              v-model="selectedRequests"
              :headers="headersTicketingRequestTable"
              :items="ticketing.ticketingRequestListFormatted || []"
              :items-per-page="15"
              width="800"
              show-expand
              show-select
              :sort-by="['request_number', 'request_date']"
              :sort-desc="true"
              item-key="request_id"
              :footer-props="{
                'items-per-page-options': [15, 30, 50, 80]
              }"
              @input="limiter"
              @toggle-select-all="selectedRequests = []"
            >
              <template v-slot:[`body.prepend`]>
                <tr>
                  <td />
                  <td />
                  <td>
                    <v-text-field
                      v-model="request_count"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="request_type"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="request_date"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="evasion_limit"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="evasion_date"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="customer"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="node_name"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="username"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="document_type"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="meteo_type"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="full_address"
                      type="text"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="request_state"
                      type="text"
                    />
                  </td>
                  <td />
                </tr>
              </template>
              <template v-slot:[`item.document_type`]="{ item }">
                <split-document-or-meteo-type :type="item.document_type" />
              </template>
              <!-- <template
                v-slot:[`item.request_state`]="{ item }"
              >
                <v-select
                  v-model="item.request_state"
                  :items="requestState"

                  @change="modifyRequestState(item)"
                />
              </template> -->
              <template
                v-slot:[`item.full_address`]="{ item }"
              >
                <p
                  v-for="(place, i) in item.locations"
                  :key="i"
                  class="address"
                >
                  {{ place.address }}
                  <map-coordinate
                    :coordinate="[place.latitude, place.longitude]"
                    :type="place.meteo_type"
                  />
                </p>
              </template>
              <template
                v-slot:[`item.meteo_type`]="{ item }"
              >
                <p
                  v-for="(m_type, i) in item.meteo_type"
                  :key="i"
                  class="address"
                >
                  {{ m_type }}
                </p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  :size="20"
                  @click="requestDetail(item)"
                >
                  mdi-card-search
                </v-icon>
              </template>
              <template
                v-slot:[`expanded-item`]="{ item }"
              >
                <td
                  :colspan="headersTicketingRequestTable.length +1"
                  style="padding:0"
                >
                  <v-data-table
                    id="locations-table"
                    :headers="headersTicketingLocationsTable"
                    :items="item.locations || []"
                    :items-per-page="15"
                    width="800"
                    hide-default-footer
                    disable-pagination
                    item-key="location_id"
                  >
                    <template v-slot:[`item.meteo_type`]="{ item }">
                      <split-document-or-meteo-type :type="item.meteo_type" />
                    </template>
                    <template
                      v-slot:[`item.address`]="{ item }"
                    >
                      <span>
                        {{ item.address }}
                        <map-coordinate
                          :coordinate="[item.latitude, item.longitude]"
                          :type="item.meteo_type"
                        />
                      </span>
                    </template>
                    <template v-slot:[`item.latitude`]="{ item }">
                      <map-coordinate
                        :coordinate="item.latitude"
                        :type="item.meteo_type"
                      />
                    </template>
                    <template v-slot:[`item.longitude`]="{ item }">
                      <map-coordinate
                        :coordinate="item.longitude"
                        :type="item.meteo_type"
                      />
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              dark
              color="#0da344"
              class="mb-2"
              :disabled="selectedRequests.length === 0"
              @click="viewSelectedRequests()"
            >
              Open selected requests
            </v-btn>
          </v-card-actions>
        </div>
        <ticketing-detail-tabs
          v-if="ticketing.viewTicketingDetailTabs"
          :requests="selectedRequestsUnformatted"
          :filter-range-date="rangeDate"
          @remove-tab="closeTab($event)"
          @close-all-tabs="closeAllTabs()"
        />
        <ticketing-detail-popup
          v-if="ticketing.viewTicketingDetailPopup"
          :request="request"
          :filter-range-date="rangeDate"
        />
      </div>
      <ticketing-search-request-list
        v-else
        :is-map="isMap"
        @set-if-is-map="setIsMap($event)"
        @is-loading="isLoading($event)"
        @show-results="showResults($event)"
        @save-filter-date="saveFilterDate($event)"
      />
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { requestStates } from '@/constants.js';
import { json2excel } from 'js2excel';
import moment from 'moment-business-days';

export default {
  components: {
    TicketingDetailPopup: () => import('@/components/services/TicketingDetailPopup'),
    TicketingDetailTabs: () => import('@/components/services/TicketingDetailTabs'),
    SplitDocumentOrMeteoType: () => import('@/components/utils/SplitDocumentOrMeteoType'),
    MapCoordinate: () => import('@/components/utils/MapCoordinate'),
    TicketingSearchRequestList: () => import('@/components/services/TicketingSearchRequestList'),
    TicketingMapRequestList: () => import('@/components/services/TicketingMapRequestList')
  },
  data () {
    return {
      rangeMenu: false,
      isResult: false,
      isMap: false,
      overlay: false,
      request_date: '',
      request_type: '',
      request_count: '',
      evasion_limit: '',
      evasion_date: '',
      customer: '',
      node_name: '',
      username: '',
      document_type: '',
      meteo_type: '',
      address: '',
      latitude: '',
      longitude: '',
      full_address: '',
      request_state: '',
      request: {},
      rangeDate: [],
      selectedRequests: [],
      selectedRequestsUnformatted: [],
      exportTableExtensions: [
        { type: 'csv', color: 'green' },
        { type: 'pdf', color: 'red' },
        { type: 'doc', color: '#2196f3' }
      ]
    };
  },
  computed: {
    ...mapState(['ticketing', 'services']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? `${moment(this.rangeDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.rangeDate[1] ? `${moment(this.rangeDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    headersTicketingRequestTable() {
      const headersToReturn = [
        {
          text: 'Request N°',
          value: 'request_count',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_count.toLowerCase());
          },
          sortable: false,
          width: 100
        },
        {
          text: 'Type',
          value: 'request_type',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Request date',
          value: 'request_date',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_date.toLowerCase());
          },
          sortable: false,
          width: 110
        },
        {
          text: 'Evasion expected',
          value: 'evasion_limit',
          filter: f => {
            return (f + '').toLowerCase().includes(this.evasion_limit.toLowerCase());
          },
          sortable: false,
          width: 110
        },
        {
          text: 'Evasion date',
          value: 'evasion_date',
          filter: f => {
            return (f + '').toLowerCase().includes(this.evasion_date.toLowerCase());
          },
          sortable: false,
          width: 110
        },
        {
          text: 'Customer',
          value: 'ragione_sociale',
          filter: f => {
            return (f + '').toLowerCase().includes(this.customer.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Node',
          value: 'node_name',
          filter: f => {
            return (f + '').toLowerCase().includes(this.node_name.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Username',
          value: 'username',
          filter: f => {
            return (f + '').toLowerCase().includes(this.username.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Full Name',
          value: 'full_name',
          align: ' d-none',
          sortable: false
        },
        {
          text: 'Document Type',
          value: 'document_type',

          filter: f => {
            return (f + '').toLowerCase().includes(this.document_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Meteo Type',
          value: 'meteo_type',

          filter: f => {
            return (f + '').toLowerCase().includes(this.meteo_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Geographical references',
          value: 'full_address',
          filter: f => {
            return (f + '').toLowerCase().includes(this.full_address.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Request State',
          value: 'request_state',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_state.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ];

      return headersToReturn;
    },
    headersTicketingLocationsTable() {
      const headersToReturn = [
        {
          text: '',
          value: '',
          sortable: false
        },
        {
          text: 'Start date',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'End date',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'Meteo Type',
          value: 'meteo_type',
          sortable: false
        },
        {
          text: 'Address',
          value: 'address',
          sortable: false
        },
        {
          text: 'Latitude',
          value: 'latitude',
          sortable: false
        },
        {
          text: 'Longitude',
          value: 'longitude',
          sortable: false
        }
      ];

      return headersToReturn;
    },
    requestState() {
      return requestStates;
    }
  },
  watch: {
    $route() {},
    '$route.params': {
      handler(newValue) {
        this.ticketing.searchFilters = {};
        this.ticketing.ticketingRequestListFormatted = [];

        if (this.$route.path !== '/ticketing-list') {
          this.isResult = true;
          this.isMap = false;
          const sixMonthsAgo = moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DD');
          const today = moment().endOf('day').format('YYYY-MM-DD');
          this.saveFilterDate([sixMonthsAgo, today]);
          this.getTicketingRequestList(newValue.id);
        } else {
          this.isResult = false;
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.services.services.length == 0 && this.$route.path !== '/ticketing-list') {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions(['storeGetTicketingRequestComplete', 'deleteTicketingRequestAction', 'editTicketingRequestStateAction', 'reorganizeSlots', 'storeListFilteredTicketingRequest']),
    limiter(e) {
      if (e.length > 10) {
        e.pop();
      }
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    async saveFilterDate(date) {
      this.rangeDate = date;
      // this.$refs.rangeMenu.save(this.rangeDate);
    },
    async changeFilterDate() {
      this.$refs.rangeMenu.save(this.rangeDate);

      this.overlay = true;

      if (this.$route.path === '/ticketing-list' && this.isResult) {
        this.ticketing.searchFilters.rangeDate = this.rangeDate;
        await this.storeListFilteredTicketingRequest(this.ticketing.searchFilters);
      } else {
        const filter_start_date = this.rangeDate[0];
        const filter_end_date = this.rangeDate[1];
        const data = {
          service_id: this.$route.params.id,
          filter_start_date: filter_start_date,
          filter_end_date: filter_end_date
        };
        await this.storeGetTicketingRequestComplete(data);
      }

      this.overlay = false;
    },
    requestDetail(item) {
      const filtered = this.ticketing.ticketingRequestList.filter(element => element.request_id === item.request_id);
      this.request = filtered[0];
      this.request.notes = filtered[0].notes.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
      this.ticketing.viewTicketingDetailPopup = true;
    },
    async getTicketingRequestList(newValue) {
      const start_date = this.rangeDate[0] || moment().subtract(3, 'months').format('YYYY-MM-DD');
      const end_date = this.rangeDate[1] || moment().format('YYYY-MM-DD');

      this.rangeDate = [start_date, end_date];

      const data = {
        service_id: newValue,
        filter_start_date: start_date,
        filter_end_date: end_date
      };

      this.overlay = true;
      await this.storeGetTicketingRequestComplete(data);
      this.overlay = false;
    },
    exportExcelFiltered() {
      const data = [];
      const allItems = [];
      const localCurrentList = JSON.parse(JSON.stringify(this.ticketing.ticketingRequestList));

      localCurrentList.forEach(request => {
        request.locations.forEach(location => {
          request.document_type = request.document_type.split(', ')[0];
          location.meteo_type = location.meteo_type.split(', ')[0];
          allItems.push({
            ...request,
            ...location
          });
        });
      });

      const headers = this.headersTicketingRequestTable.concat(this.headersTicketingLocationsTable).map((header) => {
        return header.value;
      });

      allItems.forEach((list) => {
        delete list.locations;
        delete list.full_address;
        const jsonList = JSON.parse(JSON.stringify(list, headers, 4));
        data.push(jsonList);
      });

      if (data.length == 0) {
        const message = 'Error export failed, retry!';
        const color = '#B7513C';
        this.passAttributesToSnackbar({
          message,
          color
        });
      }

      try {
        json2excel({
          data,
          name: 'excel-table',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        const message = 'Error export failed, retry!';
        const color = '#B7513C';
        this.passAttributesToSnackbar({
          message,
          color
        });
      }
    },
    async modifyRequestState(item) {
      if (item.request_state == 'EMITTED') {
        item.evasion_date = moment().format('YYYY-MM-DD');
      } else {
        item.evasion_date = '';
      }

      const requestData = {
        service_id: item.service_id,
        request_id: item.request_id,
        request_state: item.request_state,
        evasion_date: item.evasion_date
      };

      this.overlay = true;
      await this.editTicketingRequestStateAction(requestData);

      if (this.ticketing.createRequestState) {
        if (this.$route.path !== '/ticketing-list') {
          this.getTicketingRequestList(item.service_id);
        } else {
          this.overlay = true;
          await this.storeListFilteredTicketingRequest(this.ticketing.searchFilters);
          this.overlay = false;
        }
      }

      this.overlay = false;
    },
    async deleteRequest(item) {
      this.overlay = true;
      const evasionLimit = item.evasion_limit ? moment(item.evasion_limit, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
      const isStandardRequest = !item.document_type.includes('provinciale') && !item.document_type.includes('Relazione');
      const dateToCheck = moment().nextBusinessDay().format('YYYY-MM-DD');
      const require_reorganize_slots = isStandardRequest && moment(evasionLimit, 'YYYY-MM-DD').isSameOrAfter(dateToCheck, 'day');
      const data = {
        request_id: item.request_id,
        request_state: item.request_state,
        evasion_limit: evasionLimit,
        slots_to_reorganize: require_reorganize_slots ? await this.reorganizeSlots(evasionLimit) : []
      };
      await this.deleteTicketingRequestAction(data);

      if (this.ticketing.createRequestState) {
        if (this.$route.path !== '/ticketing-list') {
          this.getTicketingRequestList(this.$route.params.id);
        } else {
          this.overlay = true;
          await this.storeListFilteredTicketingRequest(this.ticketing.searchFilters);
          this.overlay = false;
        }
      }

      this.overlay = false;
    },
    selectFromMap(item) {
      const features = item.values_.features;
      const requests = new Set(features.map(e => e.values_.request_id));

      if (requests.size) {
        if (requests.size > 1) {
          this.selectedRequests = this.ticketing.ticketingRequestListFormatted.filter(element => features.some(e => e.values_.request_id === element.request_id));
          this.viewSelectedRequests();
        } else {
          this.requestDetail(features[0].values_);
        }
      }
    },
    viewSelectedRequests() {
      const requests_id = [];
      this.selectedRequests.forEach(item => requests_id.push(item.request_id));
      this.selectedRequestsUnformatted = this.ticketing.ticketingRequestList.filter(element => requests_id.indexOf(element.request_id) !== -1);
      this.selectedRequestsUnformatted.forEach(item => item.notes = item.notes.replace(/\\n/g, '\n').replace(/\\t/g, '\t'));
      this.ticketing.viewTicketingDetailTabs = true;
    },
    closeTab(item) {
      this.selectedRequests = this.selectedRequests.filter(element => element.request_number !== item);
      this.selectedRequestsUnformatted = this.selectedRequests.filter(element => element.request_number !== item);

      if (this.selectedRequests.length < 1) {
        this.ticketing.viewTicketingDetailTabs = false;
      }
    },
    closeAllTabs() {
      if (this.isMap) {
        this.selectedRequests = [];
        this.selectedRequestsUnformatted = [];
        this.ticketing.viewTicketingDetailTabs = false;
      }
    },
    async refreshSearch() {
      this.overlay = true;
      await this.storeListFilteredTicketingRequest(this.ticketing.searchFilters);
      this.overlay = false;
    },
    isLoading(bool) {
      this.overlay = bool;
    },
    showResults(bool) {
      this.isResult = bool;
    },
    setIsMap(bool) {
      this.isMap = bool;
    },
    toggleSearchFilters() {
      this.isResult = !this.isResult;
    }
  }
};
</script>

<style scoped>
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.flex-margin {
  margin-top: 2%
}
.div-width {
  width: 300px;
}
input::-webkit-input-placeholder {
    font-size: 2px;
    line-height: 3;
}
.v-select /deep/ {
  font-size: 14px !important;
}
.button-margin{
  margin-left: 70%;
  margin-top: 2%;
}
.address  {
  line-height: 5px;
  margin-top: 18px;
}
#locations-table{
  background: #e8f5e9;
}
#requests-table {
  white-space: nowrap !important;
}

</style>
