<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row>
      <v-col>
        <policy-table
          :title="'APP POLICY'"
          @newPolicyEvent="createPolicyEvent"
          @editPolicyEvent="updatePolicyEvent($event)"
          @configurationPolicyEvent="updateConfigurationPolicyEvent($event)"
        />
      </v-col>
    </v-row>
    <div v-if="showCreatePolicy == true">
      <v-row>
        <v-col>
          <create-app-policy
            :form="item_form"
            :disable-ws-card="disableWScard"
            @updateConfirmedPolicy="confirmPolicyEvent()"
            @confirmedPoolicy="switchShowCreate($event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    PolicyTable: () => import('@/components/policy/PolicyTable'),
    CreateAppPolicy: () => import('@/components/policy/CreateAppPolicy')
  },
  data() {
    return {
      showCreatePolicy: false,
      item_form: {
        policy_id: 0,
        policy_name: '',
        policy_description: ''
      },
      disableWScard: true,
      overlay: false
    };
  },
  computed: {
    ...mapState(['policy', 'services']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },
  methods: {
    ...mapActions(['storeCustomerCRMID', 'storeServices']),
    switchShowCreate(evt) {
      this.showCreatePolicy = evt;
    },
    async createPolicyEvent() {
      this.overlay = true;
      this.policy.isModifingPolicy = false;
      this.item_form = {
        service_id: '',
        policy_id: 0,
        policy_name: '',
        policy_description: ''
      };
      await this.storeServices();
      this.showCreatePolicy = true;
      this.$vuetify.goTo(this.target, this.options);
      this.overlay = false;
    },
    async updatePolicyEvent(item) {
      this.overlay = true;
      this.policy.isModifingPolicy = false;
      this.showCreatePolicy = false;
      this.update_policy = false;
      await this.storeServices();
      this.item_form = JSON.parse(JSON.stringify(item));
      this.item_form.service = {};
      this.item_form.service = this.services.activeServices.filter(service => service.service_id === item.service_id)[0];
      this.storeCustomerCRMID(item);
      this.update_policy = true;
      this.showCreatePolicy = true;
      this.policy.isModifingPolicy = true;
      this.$vuetify.goTo(this.target, this.options);
      this.overlay = false;
    },
    async updateConfigurationPolicyEvent(item) {
      this.overlay = true;
      this.storeCustomerCRMID(item);
      await this.storeServices();
      this.services.service_id = item.service_id;
      this.policy.blankPolicy = item;
      this.policy.isModifingPolicy = true;
      this.$router.push('configuration-app-policy');
      this.overlay = false;
    },
    changeDisableWScard() {
      this.disableWScard = false;
    },
    confirmPolicyEvent() {
      this.overlay = true;
      this.policy.isModifingPolicy = false;
      this.showCreatePolicy = false;
      this.update_policy = false;
      this.update_policy = true;
      this.showCreatePolicy = true;
      this.policy.isModifingPolicy = true;
      this.overlay = false;
    }
  }
};
</script>
