import {
  getPolicies,
  newPolicy,
  updatePolicy,
  deletePolicy,
  checkUserPolicy,
  createServicesPolicy,
  editServicesPolicy,
  getServicesPolicy,
  configurationDocumentalServicesPolicy,
  configurationUrlServicesPolicy,
  configurationWorkspaceServicesPolicy,
  updateWorkspaceServicesPolicy,
  linkUserPolicies,
  unlinkUserPolicy,
  deleteServicePolicy,
  getEnabledServicesPolicy,
  listConfigurationPolicy,
  getConfigurationWorkspacePolicy,
  deleteDocumentalUrlNode
} from '@/api/api.js';

export const state = {
  headers: [
    {
      text: 'Policy ID',
      align: 'start',
      sortable: false,
      value: 'policy_id'
    },
    { text: 'Policy Name', value: 'policy_name' },
    { text: 'Policy Description', value: 'policy_description' },
    { text: 'Policy State', value: 'policy_state' },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  policies: [],
  blankPolicy: {
    policy_id: '',
    policy_name: '',
    policy_description: ''
  },
  isModifingPolicy: null,
  nodeToAdd: [],
  activePolicies: [],
  enabledServicesPolicy: [],
  storeConfigurationPolicy: [],
  wsConfigurationPolicy: [],
  wsConfigurationPolicyArray: [],
  nodeToDelete: [],
  policy_id: ''
};

export const mutations = {
  GET_POLICIES(state, policiesArray) {
    state.policies = policiesArray;
  },
  NEW_POLICY(state, policyToAdd) {
    state.policies.push(policyToAdd.data.createPolicy);
    state.blankPolicy = policyToAdd.data.createPolicy;
  },
  UPDATE_POLICY(state, policy) {
    state.policies.forEach(element => {
      if (element.policy_id == policy.data.updatePolicy.policy_id) {
        element.policy_name = policy.data.updatePolicy.policy_name;
        element.policy_description =
          policy.data.updatePolicy.policy_description;
      }
    });

    state.blankPolicy = policy.data.createPolicy;
  },
  UPDATE_APP_POLICY(state, policy) {
    state.policies.forEach(element => {
      if (element.policy_id == policy.policy_id) {
        element.policy_name = policy.policy_name;
        element.policy_description = policy.policy_description;
      }
    });

    state.blankPolicy = policy.data.createPolicy;
  },
  DELETE_POLICY(state, delete_policy_id) {
    state.policies.forEach(element => {
      if (element.policy_id == delete_policy_id) {
        const index = state.policies.indexOf(element);
        state.policies.splice(index, 1);
      }
    });
  },
  SWITCH_IS_MODIFING_POLICY(state, data) {
    state.isModifingPolicy = data;
  },
  SET_ACTIVE_POLICIES(state, data) {
    state.activePolicies = data;
  },
  GET_ENABLED_SERVICES_POLICY(state, policiesArray) {
    state.enabledServicesPolicy = policiesArray;
  },
  GET_CONFIGURATION_POLICY(state, configurationPolicy) {
    state.nodeToAdd = [];
    state.storeConfigurationPolicy = [];

    if (configurationPolicy.URL.length > 0) {
      configurationPolicy.URL.forEach(item => {
        state.nodeToAdd.push(item);
        state.storeConfigurationPolicy.push(item);
      });
    }

    if (configurationPolicy.DOC.length > 0) {
      configurationPolicy.DOC.forEach(item => {
        state.nodeToAdd.push(item);
        state.storeConfigurationPolicy.push(item);
      });
    }
  },
  GET_WS_CONFIGURATION_POLICY(state, wsConfigurationPolicy) {
    state.wsConfigurationPolicy = wsConfigurationPolicy;
  },
  GET_WS_CONFIGURATION_POLICY_ARRAY(state, wsConfigurationPolicy) {
    state.wsConfigurationPolicyArray.push(wsConfigurationPolicy);
  }
};

export const actions = {
  async unlinkUserPolicyAction({ dispatch }, data) {
    try {
      await unlinkUserPolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy unlinked from User',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'unlinkUserPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async linkUserPoliciesAction({ dispatch }, data) {
    try {
      await linkUserPolicies(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy linked to User',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkUserPoliciesAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  storeIsModifingPolicy({ commit }, data) {
    commit('SWITCH_IS_MODIFING_POLICY', data);
  },
  async storePolicies({ dispatch, commit }) {
    try {
      const policiesArray = await getPolicies();
      commit('GET_POLICIES', policiesArray.data.listPolicies);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeIsModifingPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeAppPolicies({ dispatch, commit }, data) {
    try {
      const policiesArray = await getServicesPolicy();
      commit('GET_POLICIES', policiesArray.data.listServicesPolicy);
      let activePolicies;

      if (data) {
        activePolicies = [
          ...policiesArray.data.listServicesPolicy.filter(e => {
            if (e.policy_state == 'ACTIVE' && e.service_id == data) {
              return e;
            }
          })
        ];
      } else {
        activePolicies = [
          ...policiesArray.data.listServicesPolicy.filter(e => {
            if (e.policy_state == 'ACTIVE') {
              return e;
            }
          })
        ];
      }

      commit('SET_ACTIVE_POLICIES', activePolicies);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAppPolicies',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeNewPolicy({ commit, dispatch }, policy) {
    try {
      const policyToAdd = await newPolicy(
        policy.policy_name,
        policy.policy_description
      );
      commit('NEW_POLICY', policyToAdd);
      dispatch('storeIsModifingPolicy', true);
      dispatch('storeCustomerCRMID', policyToAdd.data.createPolicy, {
        root: true
      });
      dispatch(
        'storeToastMessage',
        {
          text: 'policy created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeNewAppPolicy({ dispatch }, data) {
    try {
      await createServicesPolicy({
        service_id: data.service.service_id,
        service_type: data.service.service_type,
        policy_name: data.policy_name,
        policy_description: data.policy_description
      });
      dispatch('storeIsModifingPolicy', true);
      dispatch(
        'storeToastMessage',
        {
          text: 'app policy created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewAppPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeUpdatedPolicy({ dispatch, commit }, policy) {
    try {
      const policyToUpdate = await updatePolicy(
        policy.policy_id,
        policy.policy_name,
        policy.policy_description
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'policy updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_POLICY', policyToUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeUpdatedAppPolicy({ dispatch }, data) {
    try {
      await editServicesPolicy({
        service_id: data.service.service_id,
        policy_id: data.policy_id,
        policy_name: data.policy_name,
        policy_description: data.policy_description
      });
      dispatch(
        'storeToastMessage',
        {
          text: 'app policy edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedAppPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteAppPolicyAction({ dispatch, commit }, data) {
    try {
      const policyToDelete = await deleteServicePolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'policy deleted',
          type: 'success'
        },
        { root: true }
      );
      commit(
        'DELETE_POLICY',
        policyToDelete.data.deleteServicePolicy.policy_id
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteAppPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteTablePolicy({ dispatch, commit }, policy_id) {
    try {
      const check = await checkUserPolicy(policy_id);

      if (check.data.checkUserPolicy.result) {
        dispatch(
          'storeToastMessage',
          {
            text: 'You can\'t delete a policy attached to an API User',
            type: 'warning'
          },
          { root: true }
        );
      } else {
        const policyToDelete = await deletePolicy(policy_id);
        dispatch(
          'storeToastMessage',
          {
            text: 'policy deleted',
            type: 'success'
          },
          { root: true }
        );
        commit('DELETE_POLICY', policyToDelete.data.deletePolicy.policy_id);
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTablePolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async configurationDocumentalServicesPolicyAction({ dispatch }, data) {
    try {
      await configurationDocumentalServicesPolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy Documental configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'configurationDocumentalServicesPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async configurationUrlServicesPolicyAction({ dispatch }, data) {
    try {
      await configurationUrlServicesPolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy Url configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'configurationUrlServicesPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async configurationWorkspaceServicesPolicyAction({ dispatch }, data) {
    try {
      await configurationWorkspaceServicesPolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy Workspace configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'configurationWorkspaceServicesPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async updateWorkspaceServicesPolicyAction({ dispatch }, data) {
    try {
      await updateWorkspaceServicesPolicy(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Policy Workspace configuration updated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'updateWorkspaceServicesPolicyAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDocumentalUrlNodeAction({ dispatch }, data) {
    try {
      await deleteDocumentalUrlNode(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Node deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDocumentalUrlNodeAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeEnabledServicesPolicy({ dispatch, commit }, data) {
    try {
      const listPolicy = await getEnabledServicesPolicy(data);
      commit(
        'GET_ENABLED_SERVICES_POLICY',
        listPolicy.data.listEnabledServicesPolicy
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeEnabledServicesPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeListConfigurationPolicy({ dispatch, commit }, data) {
    try {
      const listPolicy = await listConfigurationPolicy(data);
      commit('GET_CONFIGURATION_POLICY', listPolicy.data.listConfigurationPolicy);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'listConfigurationPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeConfigurationWorkspacePolicy({ dispatch, commit, state }, data) {
    try {
      if (Array.isArray(data)) {
        state.wsConfigurationPolicyArray = [];

        for await (const item of data) {
          const resp = await getConfigurationWorkspacePolicy(item);
          commit('GET_WS_CONFIGURATION_POLICY_ARRAY', resp.data.getConfigurationWorkspacePolicy);
        }
      } else {
        const resp = await getConfigurationWorkspacePolicy(data);
        commit('GET_WS_CONFIGURATION_POLICY', resp.data.getConfigurationWorkspacePolicy);
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getConfigurationWorkspacePolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
