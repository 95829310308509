import {
  getTechUsersByCustomer,
  newTechUser,
  updateTechUser,
  deleteTechUser,
  changeTechUserState,
  getPoliciesTU
} from '@/api/api.js';

// --------------------- STATE -----------------------------

export const state = {
  headers: [
    {
      text: 'Username',
      align: 'start',
      sortable: true,
      value: 'username'
    },
    { text: 'Email', value: 'email' },
    { text: 'Policy ID', value: 'policy_id' },
    { text: 'State', value: '_state', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  tUsers: [],
  tPolicies: [],
  isModifingTechUser: null
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  SWITCH_T_USER_STATE(state, index) {
    state.tUsers[index]._state = !state.tUsers[index]._state;
  },
  GET_TECH_USERS(state, tUsersArray) {
    state.tUsers = tUsersArray;
  },
  NEW_TECH_USER(state, userToAdd) {
    state.tUsers.push(userToAdd.data.createTechUser);
  },
  UPDATE_TECH_USER(state, user) {
    state.tUsers.forEach(element => {
      if (element.username == user.data.updateTechUser.username) {
        element.email = user.data.updateTechUser.email;
        element.policy_id = user.data.updateTechUser.policy_id;
      }
    });
  },
  DELETE_TECH_USER(state, delete_username) {
    state.tUsers.forEach(element => {
      if (element.username == delete_username) {
        const index = state.tUsers.indexOf(element);
        state.tUsers.splice(index, 1);
      }
    });
  },
  GET_POLICIES_TU(state, policiesArray) {
    state.tPolicies = policiesArray;
  },
  SWITCH_IS_MODIFING_TECH_USER(state, data) {
    state.isModifingTechUser = data;
  }
};

// ------------------------ ACTIONS ---------------------------

export const actions = {
  storeIsModifingTechUser({ commit }, data) {
    commit('SWITCH_IS_MODIFING_TECH_USER', data);
  },
  storeSwitchTUState({ commit }, selected) {
    for (let i = 0;i < selected.length;i++) {
      for (let j = 0;j < state.tUsers.length;j++) {
        if (state.tUsers[j].username === selected[i].username) {
          commit('SWITCH_T_USER_STATE', j);
        }
      }
    }
  },

  async switchTUState({ state, dispatch }, selected) {
    if (selected.length > 0) {
      for (let i = 0;i < selected.length;i++) {
        for (let j = 0;j < state.tUsers.length;j++) {
          if (state.tUsers[j].username === selected[i].username) {
            try {
              await changeTechUserState(
                state.tUsers[j].username,
                !state.tUsers[j]._state
              );
            } catch (err) {
              dispatch(
                'storeToastMessage',
                {
                  text: 'switchTUState',
                  errObj: err,
                  type: 'danger'
                },
                { root: true }
              );
            }
          }
        }
      }

      dispatch('storeSwitchTUState', selected);
      dispatch(
        'storeToastMessage',
        {
          text: 'User state switched',
          type: 'success'
        },
        { root: true }
      );
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Please select one or more API users',
          type: 'warning'
        },
        { root: true }
      );
    }
  },

  async storeTechUsers({ commit, dispatch }, crm_id) {
    try {
      const tech_users = await getTechUsersByCustomer(crm_id);
      commit('GET_TECH_USERS', tech_users.data.listTechUsersByCustomer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeTechUsers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewTechUser({ commit, dispatch }, tech_user) {
    try {
      const userToAdd = await newTechUser(
        tech_user.username,
        tech_user.email,
        tech_user.policy_id,
        tech_user.customer_id
      );
      commit('NEW_TECH_USER', userToAdd);
      dispatch(
        'storeToastMessage',
        {
          text: 'User created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewTechUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeUpdatedTechUser({ commit, dispatch }, tech_user) {
    try {
      const userToUpdate = await updateTechUser(
        tech_user.username,
        tech_user.email,
        tech_user.policy_id
      );
      userToUpdate.data.updateTechUser.email = tech_user.email;
      userToUpdate.data.updateTechUser.policy_id = tech_user.policy_id;
      commit('UPDATE_TECH_USER', userToUpdate);
      dispatch(
        'storeToastMessage',
        {
          text: 'User updated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedTechUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteTableTechUser({ commit, dispatch }, username) {
    try {
      const userToDelete = await deleteTechUser(username);
      commit('DELETE_TECH_USER', userToDelete.data.deleteTechUser.username);
      dispatch(
        'storeToastMessage',
        {
          text: 'User deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTableTechUser',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storePoliciesTU({ commit, dispatch }) {
    try {
      const policiesArray = await getPoliciesTU();
      commit('GET_POLICIES_TU', policiesArray.data.listPolicies);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storePoliciesTU',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
