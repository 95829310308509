import Vue from 'vue';
import Vuex from 'vuex';
import * as policy from '@/store/modules/policy';
import * as tech_user from '@/store/modules/tech_user';
import * as app_user from '@/store/modules/app_user';
import * as admin from '@/store/modules/admin';
import * as customer from '@/store/modules/customer';
import * as domain from '@/store/modules/domain';
import * as services from '@/store/modules/services';
import * as wsService from '@/store/modules/wsService';
import * as layers from '@/store/modules/layers';
import * as geoserver_data from '@/store/modules/geoserver_data';
import * as file_transfer from '@/store/modules/file_transfer';
import * as ticketing from '@/store/modules/ticketing';
import * as map from '@/store/modules/map';
import { createModule, ADD_TOAST_MESSAGE } from 'vuex-toast';
import { getErrorMessage, log } from '@/constants.js';


const DISMISS_AFTER = 6000;

Vue.use(Vuex);

const set = (property) => (state, payload) => (state[property] = payload);
const toggle = (property) => (state) => (state[property] = !state[property]);

export default new Vuex.Store({
  modules: {
    policy,
    app_user,
    tech_user,
    customer,
    admin,
    domain,
    geoserver_data,
    services,
    wsService,
    layers,
    file_transfer,
    ticketing,
    map,
    toast: createModule({
      dismissInterval: DISMISS_AFTER
    })
  },
  state: {
    drawer: null,
    customerCRMID: null,
    crm_id: null,
    disableTab: true,
    disableHierarchyTab: true,
    username: ''
  },
  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    STORE_CUSTOMER_CRMID(state, data) {
      state.customerCRMID = data;
    },
    STORE_CRMID(state, data) {
      state.crm_id = data;
    },
    SWITCH_TAB_STATE(state) {
      state.disableTab = !state.disableTab;
    },
    USERNAME(state, u) {
      state.username = u;
    }
  },
  actions: {
    storeCustomerCRMID({ commit }, data) {
      commit('STORE_CUSTOMER_CRMID', data);
    },
    storeCRMID({ commit }, data) {
      commit('STORE_CRMID', data);
    },
    switchTabState({ commit }) {
      commit('SWITCH_TAB_STATE');
    },
    storeUsername({ commit }, username) {
      commit('USERNAME', username);
    },
    storeToastMessage({ dispatch }, error) {
      log.error(error.text);
      const err = error.errObj ? getErrorMessage(error.errObj) : error.text;
      log.error(error);
      dispatch(ADD_TOAST_MESSAGE, {
        text: err,
        type: error.type
      });
    }
  },
  getters: {}
});
