<template>
  <div>
    <div v-if="$route.params.type == 'URL' || $route.params.type == 'DOC' || $route.params.type == 'REPORT-HUB'">
      <ConfigurationHierarchyService />
    </div>
    <div v-if="$route.params.type == 'WS'">
      <ConfigurationWorkspaceService />
    </div>
    <div v-if="$route.params.type == 'TICKET'">
      <ConfigurationTicketingService />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ConfigurationHierarchyService: () =>
      import('@/components/services/ConfigurationHierarchyService'),
    ConfigurationWorkspaceService: () =>
      import('@/components/services/ConfigurationWorkspaceService'),
    ConfigurationTicketingService: () =>
      import('@/components/services/ConfigurationTicketingService')
  }
};
</script>
