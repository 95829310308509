<template>
  <v-container>
    <v-row>
      <v-col>
        <layer-table
          v-if="customer.crm_id === ''"
          @createLayerEvent="createLayerEvent"
          @editLayerEvent="editLayerEvent($event)"
          @translationLayerEvent="translationLayerEvent($event)"
        />
        <customer-layer-table
          v-else
          @createLayerEvent="createLayerEvent"
          @editLayerEvent="editLayerEvent($event)"
          @translationLayerEvent="translationLayerEvent($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <create-layer
          v-if="showCreateLayer"
          :layer-form="layerForm"
          :render-file-map="renderFileMap"
          @closeCreate="showCreateLayer = false"
        />
        <layer-translation
          v-if="showTranslateLayer"
          @closeTranslate="showTranslateLayer = false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    LayerTable: () => import('@/components/layers/LayerTable'),
    CustomerLayerTable: () => import('@/components/layers/CustomerLayerTable'),
    CreateLayer: () => import('@/components/layers/CreateLayer'),
    LayerTranslation: () => import('@/components/layers/LayerTranslation')
  },
  data() {
    return {
      showCreateLayer: false,
      showTranslateLayer: false,
      renderFileMap: false,
      layerForm: {}
    };
  },
  computed: {
    ...mapState(['layers', 'file_transfer', 'customer']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },
  methods: {
    ...mapActions([]),
    createLayerEvent() {
      this.showCreateLayer = false;
      this.$nextTick(() => {
        this.showCreateLayer = true;
      });

      this.layers.isTranslating = false;
      this.layers.isEditing = false;
      this.layers.isChangingFile = false;
      this.renderFileMap = false;
      const id = uuidv4();
      this.layerForm = {
        global_layer_id: id,
        layer_name: '',
        layer_description: '',
        layer_polygon: '',
        color: '',
        width: ''
      };
      this.$vuetify.goTo(this.target, this.options);
    },
    async editLayerEvent(item) {
      this.showTranslateLayer = false;
      this.showCreateLayer = false;
      this.$nextTick(() => {
        this.showCreateLayer = true;
      });

      if (this.layers.vector != null || this.layers.vector != undefined) {
        this.layers.map.removeLayer(this.layers.vector);
      }

      this.layers.isTranslating = false;
      this.layers.isEditing = true;
      this.layers.isChangingFile = false;
      this.renderFileMap = true;
      this.layers.storeLayerForm = item;
      this.layers.fileChoice = item.layer_type;
      this.layerForm = JSON.parse(JSON.stringify(item));

      this.$vuetify.goTo(this.target, this.options);
    },
    translationLayerEvent(item) {
      this.showCreateLayer = false;
      this.showTranslateLayer = false;
      this.$nextTick(() => {
        this.showTranslateLayer = true;
      });
      this.layers.isTranslating = true;
      this.layers.global_layer_id = item.global_layer_id;
      this.layers.layer_name = item.layer_name;
      this.layers.storeLayerForm = JSON.parse(JSON.stringify(item));
    }
  }
};
</script>
