import {
  getTechUsersByCustomer,
  getAppUsersByCustomer,
  getCustomers,
  newCustomer,
  updateCustomer,
  deleteCustomer,
  updateLogoCustomer,
  stateCustomer,
  createCustomerManuals,
  deleteCustomerManuals,
  listCustomerManuals,
  listCustomerCommonServices,
  getDefaultService,
  createDefaultService,
  updateDefaultService,
  deleteDefaultService,
  createEditTranslation,
  getTranslation
} from '@/api/api.js';
import { log } from '@/constants.js';

export const state = {
  headers: [
    {
      text: 'CRM ID',
      align: 'start',
      sortable: false,
      value: 'crm_id'
    },
    { text: 'Ragione Sociale', value: 'ragione_sociale' },
    { text: 'Customer Description', value: 'c_description' },
    { text: 'State', value: 'state', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  crm_id: '',
  customers: [],
  customerManuals: [],
  customerCommonServices: [],
  storeTranslationManual: [],
  defaultService: {},
  isModifingCustomer: false,
  editCustomer: false,
  linkService: false
};

export const mutations = {
  EDIT_CUSTOMER_CHANGED(state, edit) {
    state.editCustomer = edit;
  },
  LINK_SERVICE_CHANGED(state, edit) {
    state.linkService = edit;
  },
  GET_CUSTOMERS(state, customersArray) {
    state.customers = customersArray;
  },
  NEW_CUSTOMER(state, customerToAdd) {
    state.customers.push(customerToAdd.data.createCustomer);
  },
  UPDATE_CUSTOMER(state, customer) {
    state.customers.forEach(element => {
      if (element.crm_id == customer.data.updateCustomer.crm_id) {
        element.ragione_sociale = customer.data.updateCustomer.ragione_sociale;
        element.c_description = customer.data.updateCustomer.c_description;
        element.latitude = customer.data.updateCustomer.latitude;
        element.longitude = customer.data.updateCustomer.longitude;
        element.zoom = customer.data.updateCustomer.zoom;
        element.map_style = customer.data.updateCustomer.map_style;
      }
    });
  },
  UPDATE_LOGO_CUSTOMER(state, customer) {
    state.customers.forEach(element => {
      if (element.crm_id == customer.data.updateLogoCustomer.crm_id) {
        element.logo = customer.data.updateLogoCustomer.logo;
      }
    });
  },
  DELETE_CUSTOMER(state, delete_crm_id) {
    state.customers.forEach(element => {
      if (element.crm_id == delete_crm_id) {
        const index = state.customers.indexOf(element);
        state.customers.splice(index, 1);
      }
    });
  },
  SWITCH_CUSTOMER_STATE(state, index) {
    state.customers[index].state = !state.customer[index].state;
  },
  CREATE_CUSTOMER_MANUALS(state, data) {
    state.customerManuals.push(data);
  },
  LIST_CUSTOMER_MANUALS(state, data) {
    state.customerManuals = [];
    state.customerManuals = data;
  },
  LIST_CUSTOMER_COMMON_SERVICES(state, data) {
    state.customerCommonServices = [];
    state.customerCommonServices = data;
  },
  GET_DEFAULT_SERVICE(state, obj) {
    const { scope, data } = obj;

    if (scope) {
      state.defaultService[scope] = data[scope];
    } else {
      state.defaultService = data;
    }
  },
  GET_TRANSLATION_MANUAL(state, translationObject) {
    state.storeTranslationManual = [];

    if (translationObject.length > 0) {
      state.storeTranslationManual = translationObject[0].label_value;
    }
  }
};

export const actions = {
  changeEditCustomer({ commit }, edit) {
    commit('EDIT_CUSTOMER_CHANGED', edit);
  },
  changeLinkService({ commit }, edit) {
    commit('LINK_SERVICE_CHANGED', edit);
  },

  async storeCustomers({ commit, dispatch }) {
    try {
      const customers = await getCustomers();
      commit('GET_CUSTOMERS', customers.data.listCustomers);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCustomers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewCustomer({ commit, dispatch }, customer) {
    try {
      const customerToAdd = await newCustomer(
        customer.crm_id,
        customer.ragione_sociale,
        customer.c_description,
        customer.latitude,
        customer.longitude,
        customer.zoom,
        customer.map_style,
        customer.state,
        customer.logo
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'customer created',
          type: 'success'
        },
        { root: true }
      );
      commit('NEW_CUSTOMER', customerToAdd);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewCustomer',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
      throw false;
    }
  },

  async storeUpdatedCustomer({ dispatch, commit }, customer) {
    try {
      const customerToUpdate = await updateCustomer(
        customer.crm_id,
        customer.ragione_sociale,
        customer.c_description,
        customer.latitude,
        customer.longitude,
        customer.zoom,
        customer.map_style
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'customer updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_CUSTOMER', customerToUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedCustomer',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeLogoCustomer({ dispatch, commit }, customer) {
    try {
      const cust = {
        crm_id: customer.crm_id,
        logo: customer.logo
      };
      const logoUpdate = await updateLogoCustomer(
        cust
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'logo updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_LOGO_CUSTOMER', logoUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeLogoCustomer',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteTableCustomer({ dispatch, commit }, crm_id) {
    const tech_users = await getTechUsersByCustomer(crm_id);
    const app_users = await getAppUsersByCustomer(crm_id);

    try {
      const containsApiUsers = tech_users.data.listTechUsersByCustomer.length > 0;
      const containsAppUsers = app_users.data.listAppUsersByCustomer.length > 0;

      if (!containsApiUsers && !containsAppUsers) {
        const customerToDelete = await deleteCustomer(crm_id);
        dispatch(
          'storeToastMessage',
          {
            text: 'customer deleted',
            type: 'success'
          },
          { root: true }
        );
        commit('DELETE_CUSTOMER', customerToDelete.data.deleteCustomer.crm_id);
      } else {
        if (containsAppUsers) {
          log.error('Error, customer contains app user');
          dispatch(
            'storeToastMessage',
            {
              text: 'This customer contains app user. Delete them to continue',
              type: 'warning'
            },
            { root: true }
          );
        }

        if (containsApiUsers) {
          log.error('Error, customer contains user api');
          dispatch(
            'storeToastMessage',
            {
              text: 'This customer contains user api. Delete them to continue',
              type: 'warning'
            },
            { root: true }
          );
        }
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createWorkspaceServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async switchCustomerState({ dispatch }, selected) {
    if (selected.length > 0) {
      for await (const item of selected) {
        try {
          await stateCustomer(
            item.crm_id,
            !item.state
          );
        } catch (err) {
          dispatch(
            'storeToastMessage',
            {
              text: 'switchCustomerState',
              errObj: err,
              type: 'danger'
            },
            { root: true }
          );
        }
      }

      dispatch(
        'storeToastMessage',
        {
          text: 'Customer state switched',
          type: 'success'
        },
        { root: true }
      );
    } else {
      dispatch(
        'storeToastMessage',
        {
          text: 'Please select one or more Customers',
          type: 'warning'
        },
        { root: true }
      );
    }
  },
  async createCustomerManualsAction({ dispatch, commit }, data) {
    try {
      const resp = await createCustomerManuals(data);
      commit('CREATE_CUSTOMER_MANUALS', resp.data.createCustomerManuals);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createCustomerManuals',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteCustomerManualsAction({ dispatch }, data) {
    try {
      await deleteCustomerManuals(data);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteCustomerManuals',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeCustomerManuals({ dispatch, commit }, customer_id) {
    try {
      const resp = await listCustomerManuals(customer_id);
      commit('LIST_CUSTOMER_MANUALS', resp.data.listCustomerManuals);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCustomerManuals',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeCustomerCommonServices({ dispatch, commit }, customer_id) {
    try {
      const resp = await listCustomerCommonServices(customer_id);
      commit('LIST_CUSTOMER_COMMON_SERVICES', resp.data.listCustomerCommonServices);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCustomerCommonServices',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeDefaultService({ dispatch, commit }, data) {
    try {
      const SCOPE = data.app_scope?.replace('-', '');
      const resp = await getDefaultService(data);
      commit('GET_DEFAULT_SERVICE', { scope: SCOPE, data: resp.data.getDefaultService });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDefaultService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createDefaultServiceAction({ dispatch }, data) {
    try {
      await createDefaultService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Default Service created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createDefaultService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async updateDefaultServiceAction({ dispatch }, data) {
    try {
      await updateDefaultService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Default Service updated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'updateDefaultService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDefaultServiceAction({ dispatch }, data) {
    try {
      await deleteDefaultService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Default service deleted.',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDefaultService',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createEditTranslationAction({ dispatch }, data) {
    try {
      await createEditTranslation(data);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createEditTranslation',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeTranslationManual({ dispatch, commit }, data) {
    try {
      const resp = await getTranslation(data);
      commit('GET_TRANSLATION_MANUAL', resp.data.getTranslation);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeTranslationLayerLabel',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
