import axios from 'axios';
import { BASE_URL } from '@/constants.js';
import { getJwtToken } from '@/api/api.js';

// ------------------ AXIOS ---------------------

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const addAuthInterceptor = token => {
  apiClient.interceptors.request.use(config => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
};

export const getWorkspaces = async () => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get('/rest/workspaces');
};

export const getWSLayers = async workspace => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get('/rest/workspaces/' + workspace + '/layers');
};

export const getLayerInfo = async (workspace, layer, type) => {
  const jwt = await getJwtToken();
  addAuthInterceptor(jwt);
  let response;

  try {
    response = await apiClient.get(`/rest/workspaces/${workspace}/layers/${layer}`);
  } catch (error) {
    throw new Error;
  }

  if (type) {
    return response.data.layer[type];
  }

  return response.data.layer;
};
