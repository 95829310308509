<template>
  <v-container>
    <v-row>
      <v-col>
        <policy-table
          :title="'API POLICY'"
          @newPolicyEvent="createPolicyEvent"
          @editPolicyEvent="updatePolicyEvent($event)"
        />
      </v-col>
    </v-row>
    <div v-if="showCreatePolicy == true">
      <v-row>
        <v-col>
          <create-api-policy
            :form="item_form"
            :disable-ws-card="disableWScard"
            @updateConfirmedPolicy="confirmPolicyEvent"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    PolicyTable: () => import('@/components/policy/PolicyTable'),
    CreateApiPolicy: () => import('@/components/policy/CreateApiPolicy')
  },
  data() {
    return {
      showCreatePolicy: false,
      item_form: {
        policy_id: 0,
        policy_name: '',
        policy_description: ''
      },
      disableWScard: true
    };
  },
  computed: {
    ...mapState(['policy']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },
  methods: {
    ...mapActions(['storeCustomerCRMID']),
    createPolicyEvent() {
      this.policy.isModifingPolicy = false;
      this.item_form = {
        policy_id: 0,
        policy_name: '',
        policy_description: ''
      };
      this.showCreatePolicy = true;
      this.$vuetify.goTo(this.target, this.options);
    },
    updatePolicyEvent(item) {
      this.policy.isModifingPolicy = false;
      this.showCreatePolicy = false;
      this.update_policy = false;
      this.item_form = JSON.parse(JSON.stringify(item));
      this.storeCustomerCRMID(this.item_form);
      this.update_policy = true;
      this.showCreatePolicy = true;
      this.policy.isModifingPolicy = true;
      this.$vuetify.goTo(this.target, this.options);
    },
    changeDisableWScard() {
      this.disableWScard = false;
    },
    confirmPolicyEvent() {
      this.policy.isModifingPolicy = false;
      this.showCreatePolicy = false;
      this.update_policy = false;
      this.item_form = JSON.parse(JSON.stringify(this.policy.blankPolicy));
      this.storeCustomerCRMID(this.item_form);
      this.update_policy = true;
      this.showCreatePolicy = true;
      this.policy.isModifingPolicy = true;
    }
  }
};
</script>
