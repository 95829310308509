<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card class="mt-4 mx-auto">
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="success"
        elevation="12"
        max-width="calc(100% - 32px)"
      >
        <v-card
          dark
          color="color_green"
        >
          <v-card-title>Evasion slot limits</v-card-title>
        </v-card>
      </v-sheet>
      <v-card-text class="pt-0">
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            :disabled="prevDisabled"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <h2>{{ calendarTitle }}</h2>
          <v-spacer />
          <v-btn
            icon
            class="ma-2"
            :disabled="nextDisabled"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="[1, 2, 3, 4, 5]"
          type="month"
          @change="updateCalendarSettings"
        >
          <template v-slot:day-label="{ date }">
            <ticketing-slot-limits-popup
              :item="ticketing.evasionLimitSlots.find(d => d.evasion_limit === date)"
              :date="date"
              :allowed-date="filterAllowedDates(date)"
              @get-all-slots="getAllSlots()"
            />
          </template>
          <template v-slot:day="{ date }">
            <div
              v-if="filterAllowedDates(date)"
              class="my-5 mx-8"
            >
              <v-row>
                <v-col>Slots:</v-col>
                <v-col>{{ countSlots(date).max_slots }}</v-col>
              </v-row>
              <v-row class="mt-n5">
                <v-col>Used:</v-col>
                <v-col>{{ countSlots(date).total_slots }} ({{ countSlots(date).can_be_moved }})</v-col>
              </v-row>
            </div>
          </template>
        </v-calendar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-business-days';

export default {
  components: {
    TicketingSlotLimitsPopup: () =>
      import('@/components/services/TicketingSlotLimitsPopup')
  },
  data () {
    return {
      overlay: false,
      nextDisabled: false,
      prevDisabled: true,
      value: '',
      calendarTitle: '',
      today: moment().format('YYYY-MM-DD'),
      nextYear: moment().add(1, 'years').format('YYYY-MM-DD'),
      businessDays: []
    };
  },
  computed: {
    ...mapState(['ticketing'])
  },
  watch: {
    $route() {}
  },
  async mounted() {
    await this.getAllSlots();
  },
  methods: {
    ...mapActions(['storeListEvasionLimitSlots']),
    updateCalendarSettings(date) {
      this.calendarTitle = moment(date.start.date).format('MMMM, YYYY');
      this.nextDisabled = moment(date.start.date).isSame(this.nextYear, 'month');
      this.prevDisabled = moment(date.start.date).isSame(this.today, 'month');

      const [year, month] = date.start.date.split('-');
      const monthBusinessDays = moment(`${year}-${month}-01`, 'YYYY-MM-DD').monthBusinessDays();
      this.businessDays = monthBusinessDays.map(day => moment(day).format('YYYY-MM-DD'));
    },
    async getAllSlots() {
      this.overlay = true;
      const datesToCheck = [...Array(2)].map((e, i) => moment().add(i, 'years').format('YYYY-'));
      await this.storeListEvasionLimitSlots(datesToCheck);
      this.overlay = false;
    },
    filterAllowedDates(date) {
      return this.businessDays.includes(date) && moment(date).isSameOrAfter(this.today, 'day') && moment(date).isSameOrBefore(this.nextYear, 'day');
    },
    countSlots(date) {
      const found = this.ticketing.evasionLimitSlots.find(d => d.evasion_limit === date);

      return {
        max_slots: found ? found.max_slots : '',
        total_slots: found ? found.total_slots : '',
        can_be_moved: found ? found.can_be_moved : ''
      };
    }
  }
};
</script>
