<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row>
      <v-col>
        <customer-table
          @toParent="itemFromTable($event)"
          @closeDialog="closeDialog"
          @renderCustomerForm="tab = null"
        />
      </v-col>
    </v-row>
    <v-row v-if="customer.editCustomer == true">
      <v-col>
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="color_green"
            slider-color="#00802d"
            slider-size="4"
            show-arrows
            grow
            dark
            @change="loadData()"
          >
            <v-tab>
              Customer Data
            </v-tab>
            <v-tab>
              Customer Layers
            </v-tab>
            <v-tab :disabled="disableTab">
              Applications
            </v-tab>
            <v-tab :disabled="disableTab">
              App Users
            </v-tab>
            <v-tab :disabled="disableHierarchyTab">
              Profiling Hierarchy
            </v-tab>
            <v-tab :disabled="disableTab">
              Applications Policies
            </v-tab>
            <v-tab :disabled="disableTab">
              Api Users
            </v-tab>
            <v-tab :disabled="disableTab">
              Manuals
            </v-tab>
            <v-tab :disabled="disableTab">
              Default Service
            </v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <customer-form
                    :key="componentKey"
                    @closeDialog="closeDialog"
                    @customResetForm="customResetForm"
                    @toParent="itemFromTable($event)"
                  />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <layers v-if="renderCustomerLayers" />
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <LinkServiceTable />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <app-user-table @closeDialog="closeDialog" />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <profiling-hierarchy-stepper
                  v-if="renderProfilingHierarchy"
                  :crm-id="item_form.crm_id"
                />
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <AppUserServices
                    v-if="renderAppUserPolicies"
                    :crm-id="item_form.crm_id"
                    @highlight-default-service-column="highlightDefaultServiceColumn()"
                  />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <tech-user-table @closeDialog="closeDialog" />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <manuals-table />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <default-service-container v-if="renderDefaultService" />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

          <v-card-actions>
            <v-btn
              class="mx-auto"
              min-width="350"
              outlined
              color="primary"
              @click="closeEdit"
            >
              Close edit
              <v-icon right>
                mdi-close-box-outline
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    CustomerTable: () => import('@/components/customer/CustomerTable'),
    CustomerForm: () => import('@/components/customer/CustomerForm'),
    TechUserTable: () =>
      import('@/components/customer/tech-user/TechUserTable'),
    AppUserTable: () => import('@/components/customer/app-user/AppUserTable'),
    LinkServiceTable: () => import('@/components/services/LinkServiceTable'),
    AppUserServices: () =>
      import('@/components/customer/app-user/AppUserServices'),
    ProfilingHierarchyStepper: () =>
      import('@/components/customer/ProfilingHierarchyStepper'),
    ManualsTable: () => import('@/components/customer/manuals/ManualsTable'),
    DefaultServiceContainer: () => import('@/components/customer/default-service/DefaultServiceContainer'),
    Layers: () =>
      import('@/views/Layers')
  },

  data() {
    return {
      tab: null,
      item_form: {
        crm_id: '',
        ragione_sociale: '',
        c_description: ''
      },
      componentKey: 0,
      overlay: false,
      renderDefaultService: false,
      renderCustomerLayers: false,
      renderProfilingHierarchy: false,
      renderAppUserPolicies: false
    };
  },
  computed: {
    ...mapState(['customer', 'disableTab', 'disableHierarchyTab', 'policy', 'services', 'ticketing', 'layers'])
  },
  beforeDestroy() {
    if (this.customer.editCustomer == true) {
      this.changeEditCustomer();
    }

    this.$store.state.disableTab = true;
  },

  methods: {
    ...mapActions([
      'changeEditCustomer',
      'changeLinkService',
      'storeCustomerCRMID',
      'storeTechUsers',
      'storeAppUsers',
      'storeCRMID',
      'storeAppPolicies',
      'setSelectPolicies',
      'storeLinkedServices',
      'storeCustomerID',
      'storeCustomerManuals',
      'storeDefaultService'
    ]),
    closeDialog() {
      this.tab = null;
      this.closeEdit();
    },
    customResetForm() {
      if (this.customer.isModifingCustomer) {
        this.item_form.ragione_sociale = '';
        this.item_form.c_description = '';
      } else {
        this.item_form.crm_id = '';
        this.item_form.ragione_sociale = '';
        this.item_form.c_description = '';
      }
    },
    itemFromTable(item) {
      this.item_form = JSON.parse(JSON.stringify(item));
      this.renderCustomerLayers = false;
      this.$nextTick(() => {
        this.renderCustomerLayers = true;
      });
      this.customer.crm_id = this.item_form.crm_id;
      this.storeCustomerCRMID(this.item_form);
      this.changeEditCustomer(true);
      this.changeLinkService(true);
      this.forceRerender();
    },
    closeEdit() {
      this.changeEditCustomer(false);
      this.changeLinkService(false);
      this.customer.isModifingCustomer = false;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async loadData() {
      switch (this.tab) {
        case 0:
          this.overlay = true;
          await this.storeCustomerCRMID(this.item_form);
          await this.storeCRMID(this.item_form.crm_id);
          await this.storeAppPolicies();
          // await this.storeTechUsers(this.item_form.crm_id);
          await this.storeAppUsers(this.item_form.crm_id);
          await this.storeCustomerID(this.item_form.crm_id);

          // await this.setSelectPolicies();
          if (this.customer.isModifingCustomer) {
            await this.storeLinkedServices(this.item_form.crm_id);
            this.services.linkedServices.forEach(item => {
              if (item.service_type == 'TICKET') {
                this.$store.state.disableHierarchyTab = false;
                this.ticketing.isProfiling = true;
              }
            });
          }

          this.overlay = false;
          break;
        case 2:
          await this.storeLinkedServices(this.item_form.crm_id);
          break;
        case 4:
          this.renderProfilingHierarchy = false;
          this.$nextTick(() => {
            this.renderProfilingHierarchy = true;
          });
          break;
        case 5:
          this.overlay = true;
          await this.setSelectPolicies();
          await this.storeDefaultService({ customer_id: this.item_form.crm_id });
          this.highlightDefaultServiceColumn();
          this.renderAppUserPolicies = false;
          this.$nextTick(() => {
            this.renderAppUserPolicies = true;
          });
          this.overlay = false;
          break;
        case 6:
          this.overlay = true;
          await this.storeTechUsers(this.item_form.crm_id);
          this.overlay = false;
          break;
        case 7:
          this.overlay = true;
          await this.storeCustomerManuals(this.item_form.crm_id);
          this.overlay = false;
          break;
        case 8:
          this.overlay = true;
          this.customer.defaultService = {};
          this.renderDefaultService = false;
          await this.storeDefaultService({ customer_id: this.item_form.crm_id });
          this.$nextTick(() => {
            this.renderDefaultService = true;
          });
          this.overlay = false;
          break;
        default:
          break;
      }
    },
    highlightDefaultServiceColumn() {
      const defaultService = [...new Set(Object.values(this.customer.defaultService).flatMap(global => global?.service_details.map(detail => detail.service_id)))];
      const defaultServiceHeaders = this.services.appUserLinkHeaders.filter(header => {
        if (defaultService.includes(header.value)) {
          header.class = 'green lighten-3';
        }

        return defaultService.includes(header.value);
      });
      this.services.appUserLinkHeaders = this.services.appUserLinkHeaders.filter(e => !defaultService.includes(e.value));
      this.services.appUserLinkHeaders.splice(1, 0, ...defaultServiceHeaders);
    }
  }
};
</script>
