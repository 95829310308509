<template>
  <v-container>
    <v-card class="mt-4 mx-auto">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="success"
        elevation="12"
        max-width="calc(100% - 32px)"
      >
        <v-card
          dark
          color="color_green"
        >
          <v-card-title v-if="layers.isCustomerLayerTranslating">
            Customer Layers Translation
          </v-card-title>
          <v-card-title v-else>
            Global Layers Translation
          </v-card-title>
        </v-card>
      </v-sheet>
      <v-form>
        <div
          v-if="isLanguageSelected == false"
          id="div-select"
          class="mx-auto mt-6 file-input-width"
        >
          <v-select
            v-model="selectedLanguage"
            class="select"
            label="Select Language"
            :items="setLanguages"
            :value="setLanguages.text"
            @change="changeSelectedLanguage(selectedLanguage)"
          />
        </div>
        <div>
          <v-row v-if="isLanguageSelected == true">
            <v-col cols="6">
              <create-layer
                :layer-form="layers.storeLayerForm"
                :is-editing="true"
                :is-translating="layers.isTranslating"
                :disable-form="true"
              />
            </v-col>
            <v-col cols="6">
              <create-layer
                :layer-form="layers.storeTranslationLayerForm"
                :is-editing="true"
                :is-translating="layers.isTranslating"
                :selected-language="selectedLanguageText"
              />
            </v-col>
          </v-row>
        </div>
        <v-row
          v-if="isLanguageSelected == true"
          class="mt-6"
        >
          <v-col cols="1">
            <v-btn
              dark
              color="warning"
              style="margin-left: 20px"
              @click="close()"
            >
              Close
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-btn
              light
              color="color_green"
              style="color:white"
              :disabled="layers.storeTranslationLayerForm.layer_name == ''"
              @click="confirm"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LANGUAGES } from '@/constants.js';

export default {
  components: {
    CreateLayer: () => import('@/components/layers/CreateLayer')
  },
  data() {
    return {
      search: '',
      overlay: false,
      loading: false,
      isLanguageSelected: false,
      selectedLanguage: '',
      selectedLanguageText: '',
      selectedLanguageValue: ''
    };
  },
  computed: {
    ...mapState(['layers', 'customer']),
    setLanguages() {
      return LANGUAGES;
    }
  },
  watch: {
    $route() {}
  },
  created() {
    if (!this.layers.isTranslating && this.layers.global_layer_id === '') {
      this.close();
    }
  },
  methods: {
    ...mapActions([
      'createTranslationLayerAction',
      'editTranslationLayerAction',
      'storeTranslationLayer'
    ]),
    async changeSelectedLanguage(item) {
      const language = item.split('-');
      this.isLanguageSelected = true;
      this.selectedLanguageValue = language[0];
      this.selectedLanguageText = language[1];
      this.layers.storeTranslationLayerForm = {
        layer_name: '',
        layer_description: ''
      };
      const dataLayer = {
        global_layer_id: this.layers.global_layer_id,
        lang: this.selectedLanguageValue,
        layer_name: this.layers.layer_name,
        layer_description: this.layers.layer_description
      };

      if (this.layers.isCustomerLayerTranslating) {
        dataLayer.crm_id = this.customer.crm_id;
      }

      this.overlay = true;
      await this.storeTranslationLayer(dataLayer);
      this.overlay = false;
    },
    async confirm() {
      this.isLanguageSelected = true;

      if (this.layers.isCustomerLayerTranslating) {
        this.layers.storeTranslationLayerForm.crm_id = this.customer.crm_id;
      }

      this.layers.storeTranslationLayerForm.lang = this.selectedLanguageValue;
      this.layers.storeTranslationLayerForm.global_layer_id = this.layers.storeLayerForm.global_layer_id;
      this.overlay = true;

      if (!this.layers.isEditingTranslation) {
        await this.createTranslationLayerAction(
          this.layers.storeTranslationLayerForm
        );
      } else {
        await this.editTranslationLayerAction(
          this.layers.storeTranslationLayerForm
        );
      }

      this.overlay = false;
      this.close();
    },
    close() {
      this.layers.isCustomerLayerTranslating = false;
      this.$emit('closeTranslate');
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

#div-select {
  display: flex;
  position: center;
  width: 35%;
}
</style>
