/* eslint-disable no-unused-vars */
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import router from '@/router';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { configuration } from './aws-exports';
import { log } from '@/constants.js';

Amplify.configure(configuration);

// -------------------- AUTH --------------------------
// You can get the current config object
export const currentConfig = Auth.configure();

export const getJwtToken = async () => {
  let jwt;
  await Auth.currentSession().then(res => {
    const accessToken = res.getAccessToken();
    jwt = accessToken.getJwtToken();
  });

  return jwt;
};

export const logOff = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    log.error(error);
  }
};

// -------------------- GRAPHQL --------------------

// -------------- ADMIN
export const getAdmin = async () =>
  await API.graphql(graphqlOperation(queries.getAdmin));

export const newAdmin = async (username, _name, surname, email) =>
  await API.graphql(
    graphqlOperation(mutations.newAdmin, {
      username,
      _name,
      surname,
      email
    })
  );

export const deleteAdmin = async username =>
  await API.graphql(
    graphqlOperation(mutations.deleteAdminUser, {
      username
    })
  );

// -------------- CUSTOMER
export const getCustomers = async () =>
  await API.graphql(graphqlOperation(queries.getCustomers));

export const newCustomer = async (crm_id, ragione_sociale, c_description, latitude, longitude, zoom, map_style, state, logo) =>
  await API.graphql(
    graphqlOperation(mutations.newCustomer, {
      crm_id,
      ragione_sociale,
      c_description,
      latitude,
      longitude,
      zoom,
      state,
      logo,
      map_style
    })
  );

export const updateCustomer = async (crm_id, ragione_sociale, c_description, latitude, longitude, zoom, map_style) =>
  await API.graphql(
    graphqlOperation(mutations.updateCustomer, {
      crm_id,
      ragione_sociale,
      c_description,
      latitude,
      longitude,
      zoom,
      map_style
    })
  );

export const deleteCustomer = async crm_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteCustomer, {
      crm_id
    })
  );

export const updateLogoCustomer = async input =>
  await API.graphql(graphqlOperation(mutations.updateLogoCustomer, { input }));

export const stateCustomer = async (crm_id, state) =>
  await API.graphql(
    graphqlOperation(mutations.stateCustomer, {
      crm_id,
      state
    })
  );

export const createCustomerManuals = async input =>
  await API.graphql(graphqlOperation(mutations.createCustomerManuals, { input }));

export const deleteCustomerManuals = async input =>
  await API.graphql(graphqlOperation(mutations.deleteCustomerManuals, { input }));

export const listCustomerManuals = async customer_id =>
  await API.graphql(graphqlOperation(queries.listCustomerManuals, { customer_id }));

export const listCustomerCommonServices = async customer_id =>
  await API.graphql(graphqlOperation(queries.listCustomerCommonServices, { customer_id }));

export const createDefaultService = async input =>
  await API.graphql(graphqlOperation(mutations.createDefaultService, { input }));

export const updateDefaultService = async input =>
  await API.graphql(graphqlOperation(mutations.updateDefaultService, { input }));

export const getDefaultService = async input =>
  await API.graphql(graphqlOperation(queries.getDefaultService, { input }));

export const deleteDefaultService = async input =>
  await API.graphql(graphqlOperation(mutations.deleteDefaultService, { input }));

export const createEditTranslation = async input =>
  await API.graphql(graphqlOperation(mutations.createEditTranslation, { input }));

// ---------- APP USER
export const getAppUsersByCustomer = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.getAppUsersByCustomer, { crm_id })
  );

export const newAppUser = async input =>
  await API.graphql(
    graphqlOperation(mutations.newAppUser, { input })
  );

export const updateAppUser = async (
  app_user_id,
  name,
  surname,
  email,
  phone,
  language,
  user_map_style
) =>
  await API.graphql(
    graphqlOperation(mutations.updateAppUser, {
      app_user_id,
      name,
      surname,
      email,
      phone,
      language,
      user_map_style
    })
  );

export const deleteAppUser = async (app_user_id, username, cognito_user_created) =>
  await API.graphql(
    graphqlOperation(mutations.deleteAppUser, {
      app_user_id,
      username,
      cognito_user_created
    })
  );

export const changeAppUserState = async (app_user_id, username, _state, cognito_user_created, _name, email) =>
  await API.graphql(
    graphqlOperation(mutations.changeAppUserState, {
      app_user_id,
      username,
      _state,
      cognito_user_created,
      _name,
      email
    })
  );

// ---------------- TECH USER
export const getTechUsersByCustomer = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.getTechUsersByCustomer, { crm_id })
  );

export const newTechUser = async (username, email, policy_id, customer_id) =>
  await API.graphql(
    graphqlOperation(mutations.newTechUser, {
      username,
      email,
      policy_id,
      customer_id
    })
  );

export const updateTechUser = async (username, email, policy_id) =>
  await API.graphql(
    graphqlOperation(mutations.updateTechUser, {
      username,
      email,
      policy_id
    })
  );

export const deleteTechUser = async username =>
  await API.graphql(
    graphqlOperation(mutations.deleteTechUser, {
      username
    })
  );

export const changeTechUserState = async (username, state) =>
  await API.graphql(
    graphqlOperation(mutations.changeTechUserState, {
      username,
      state
    })
  );

export const getPoliciesTU = async () =>
  await API.graphql(graphqlOperation(queries.getPoliciesTU));

// --------------- POLICY
export const getPolicies = async () =>
  await API.graphql(graphqlOperation(queries.getPolicies));

export const newPolicy = async (policy_name, policy_description) =>
  await API.graphql(
    graphqlOperation(mutations.newPolicy, {
      policy_name,
      policy_description
    })
  );

export const updatePolicy = async (
  policy_id,
  policy_name,
  policy_description
) =>
  await API.graphql(
    graphqlOperation(mutations.updatePolicy, {
      policy_id,
      policy_name,
      policy_description
    })
  );

export const deletePolicy = async policy_id =>
  await API.graphql(
    graphqlOperation(mutations.deletePolicy, {
      policy_id
    })
  );

// --------------- WORKSPACE
export const getWorkspacesByPolicy = async policy_id =>
  await API.graphql(
    graphqlOperation(queries.getWorkspacesByPolicy, { policy_id })
  );

export const newWorkspace = async (
  workspace_name,
  n_calls,
  start_time,
  stop_time,
  date_type,
  policy_id
) =>
  await API.graphql(
    graphqlOperation(mutations.newWorkspace, {
      workspace_name,
      n_calls,
      start_time,
      stop_time,
      date_type,
      policy_id
    })
  );

export const updateWorkspace = async (
  workspace_name,
  n_calls,
  start_time,
  stop_time,
  date_type,
  policy_id
) =>
  await API.graphql(
    graphqlOperation(mutations.updateWorkspace, {
      workspace_name,
      n_calls,
      start_time,
      stop_time,
      date_type,
      policy_id
    })
  );

export const deleteWorkspace = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(mutations.deleteWorkspace, {
      workspace_name,
      policy_id
    })
  );

export const getDomainsWS = async () =>
  await API.graphql(graphqlOperation(queries.getDomainsWS));

export const getDomainsByWS = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(queries.getDomainsByWS, { workspace_name, policy_id })
  );

// --------------- LAYER
export const getDBLayers = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(queries.getDBLayers, { workspace_name, policy_id })
  );

export const createLayer = async (workspace_name, policy_id, layer_name) =>
  await API.graphql(
    graphqlOperation(mutations.createLayer, {
      workspace_name,
      policy_id,
      layer_name
    })
  );

export const deleteLayer = async (workspace_name, policy_id, layer_name) =>
  await API.graphql(
    graphqlOperation(mutations.deleteLayer, {
      workspace_name,
      policy_id,
      layer_name
    })
  );

// --------------- WS-DOMAIN
export const addDomain = async (workspace_name, policy_id, domain_id) =>
  await API.graphql(
    graphqlOperation(mutations.addDomain, {
      workspace_name,
      policy_id,
      domain_id
    })
  );

export const deleteDomainWS = async (workspace_name, policy_id, domain_id) =>
  await API.graphql(
    graphqlOperation(mutations.deleteDomainWS, {
      workspace_name,
      policy_id,
      domain_id
    })
  );

// --------------- DOMAIN
export const getDomains = async () =>
  await API.graphql(graphqlOperation(queries.getDomains));

export const newDomain = async (
  domain_id,
  domain_name,
  domain_description,
  polygon
) =>
  await API.graphql(
    graphqlOperation(mutations.newDomain, {
      domain_id,
      domain_name,
      domain_description,
      polygon
    })
  );

export const updateDomain = async (
  domain_id,
  domain_name,
  domain_description,
  polygon
) =>
  await API.graphql(
    graphqlOperation(mutations.updateDomain, {
      domain_id,
      domain_name,
      domain_description,
      polygon
    })
  );

export const deleteDomain = async domain_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteDomain, {
      domain_id
    })
  );

// ---------- CHECK

export const checkUserPolicy = async policy_id =>
  await API.graphql(graphqlOperation(queries.checkUserPolicy, { policy_id }));

export const checkUserDomain = async domain_id =>
  await API.graphql(graphqlOperation(queries.checkUserDomain, { domain_id }));

// ---------- SERVICES
export const createService = async (description, service_name, service_type) =>
  await API.graphql(
    graphqlOperation(mutations.createService, {
      description,
      service_name,
      service_type
    })
  );

export const createTranslationService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createTranslationService, {
      input
    })
  );

export const editService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editService, {
      input
    })
  );

export const editTranslationService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTranslationService, {
      input
    })
  );

export const getServices = async () =>
  await API.graphql(graphqlOperation(queries.getServices));

export const getTranslationServices = async input =>
  await API.graphql(
    graphqlOperation(queries.getTranslationServices, { input })
  );

export const deleteService = async (
  service_id,
  service_name,
  service_type,
  ws_id
) =>
  await API.graphql(
    graphqlOperation(mutations.deleteService, {
      service_id,
      service_name,
      service_type,
      ws_id
    })
  );

export const linkServices = async input =>
  await API.graphql(graphqlOperation(mutations.linkServices, { input }));

export const unlinkServices = async input =>
  await API.graphql(graphqlOperation(mutations.unlinkServices, { input }));

export const listLinkedServices = async crm_id =>
  await API.graphql(graphqlOperation(queries.listLinkedServices, { crm_id }));

export const listLinkedCustomers = async input =>
  await API.graphql(graphqlOperation(queries.listLinkedCustomers, { input }));

// ---------- WORKSPACE
export const createWorkspaceService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createWorkspaceService, { input })
  );

export const getWorkspaceConfiguration = async input =>
  await API.graphql(
    graphqlOperation(queries.getWorkspaceConfiguration, { input })
  );

export const updateWorkspaceService = async input =>
  await API.graphql(
    graphqlOperation(mutations.updateWorkspaceService, { input })
  );

// ---------- URL SERVICE
export const getUrlServiceConfiguration = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getUrlServiceConfiguration, {
      service_id
    })
  );

export const createUrlService = async input =>
  await API.graphql(graphqlOperation(mutations.createUrlService, { input }));

export const editUrlService = async input =>
  await API.graphql(graphqlOperation(mutations.editUrlService, { input }));

export const createUrlServiceTranslation = async input =>
  await API.graphql(
    graphqlOperation(mutations.createUrlServiceTranslation, { input })
  );

export const deleteUrlService = async input =>
  await API.graphql(graphqlOperation(mutations.deleteUrlService, { input }));

// ---------- DOCUMENTAL SERVICE
export const getDocumentalServiceConfiguration = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getDocumentalServiceConfiguration, {
      service_id
    })
  );

export const createDocumentalService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createDocumentalService, { input })
  );

export const editDocumentalService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editDocumentalService, { input })
  );

export const createDocumentalServiceTranslation = async input =>
  await API.graphql(
    graphqlOperation(mutations.createDocumentalServiceTranslation, { input })
  );

export const deleteDocumentalService = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteDocumentalService, { input })
  );

// ---------- REPORT HUB SERVICE
export const getReportHubServiceConfiguration = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getReportHubServiceConfiguration, {
      service_id
    })
  );

export const createReportHubService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createReportHubService, { input })
  );

export const editReportHubService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editReportHubService, { input })
  );

export const createReportHubServiceTranslation = async input =>
  await API.graphql(
    graphqlOperation(mutations.createReportHubServiceTranslation, { input })
  );

export const deleteReportHubService = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteDocumentalService, { input })
  );

// --------------- GLOBAL LAYER
export const createGlobalLayer = async input =>
  await API.graphql(graphqlOperation(mutations.createGlobalLayer, { input }));

export const editGlobalLayer = async input =>
  await API.graphql(graphqlOperation(mutations.editGlobalLayer, { input }));

export const deleteGlobalLayer = async input =>
  await API.graphql(graphqlOperation(mutations.deleteGlobalLayer, { input }));

export const createGlobalLayerTranslaction = async input =>
  await API.graphql(
    graphqlOperation(mutations.createGlobalLayerTranslaction, { input })
  );

export const editGlobalLayerTranslaction = async input =>
  await API.graphql(
    graphqlOperation(mutations.editGlobalLayerTranslaction, { input })
  );

export const listGlobalLayerTranslaction = async input =>
  await API.graphql(
    graphqlOperation(queries.listGlobalLayerTranslaction, { input })
  );

export const getGlobalLayer = async service_id =>
  await API.graphql(graphqlOperation(queries.getGlobalLayer, {
    service_id
  }));

export const getCustomerLayer = async crm_id =>
  await API.graphql(graphqlOperation(queries.getCustomerLayer, { crm_id }));

export const deleteCustomerLayer = async input =>
  await API.graphql(graphqlOperation(mutations.deleteCustomerLayer, { input }));

// ---------- TRANSLATIONS
export const getTranslationNodes = async input =>
  await API.graphql(
    graphqlOperation(queries.getTranslationNodes, {
      input
    })
  );

export const editTranslationNodes = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTranslationNodes, {
      input
    })
  );

export const getTranslation = async input =>
  await API.graphql(
    graphqlOperation(queries.getTranslation, {
      input
    })
  );

// ---------- POLICY
export const createServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.createServicesPolicy, {
      input
    })
  );

export const editServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.editServicesPolicy, {
      input
    })
  );

export const getServicesPolicy = async () =>
  await API.graphql(graphqlOperation(queries.getServicesPolicy));

export const configurationDocumentalServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.configurationDocumentalServicesPolicy, {
      input
    })
  );

export const configurationUrlServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.configurationUrlServicesPolicy, {
      input
    })
  );

export const configurationWorkspaceServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.configurationWorkspaceServicesPolicy, {
      input
    })
  );

export const updateWorkspaceServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(mutations.updateWorkspaceServicesPolicy, {
      input
    })
  );

export const linkUserPolicies = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkUserPolicies, {
      input
    })
  );

export const unlinkUserPolicy = async input => {
  return await API.graphql(
    graphqlOperation(mutations.unlinkUserPolicy, {
      input
    })
  );
};

export const listLinkedAppUserPolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.listLinkedAppUserPolicy, { input })
  );

export const listConfigurationPolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.listConfigurationPolicy, { input })
  );

export const deleteServicePolicy = async input =>
  await API.graphql(graphqlOperation(mutations.deleteServicePolicy, { input }));

export const getConfigurationWorkspacePolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.getConfigurationWorkspacePolicy, { input })
  );

// ---------- TICKETING SERVICE
export const getTicketingServiceConfiguration = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getTicketingServiceConfiguration, {
      service_id
    })
  );

export const createTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createTicketingService, { input })
  );

export const editTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTicketingService, { input })
  );

export const deleteTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteTicketingService, { input })
  );

export const createEditTicketingServiceTranslation = async input =>
  await API.graphql(
    graphqlOperation(mutations.createEditTicketingServiceTranslation, { input })
  );

export const getTicketingServiceTranslation = async input =>
  await API.graphql(
    graphqlOperation(queries.getTicketingServiceTranslation, {
      input
    })
  );

export const createProfilingTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.createProfilingTicketingService, { input })
  );

export const getProfilingTicketingService = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getProfilingTicketingService, {
      service_id
    })
  );

export const editProfilingTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.editProfilingTicketingService, { input })
  );

export const deleteProfilingTicketingService = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteProfilingTicketingService, { input })
  );

export const listTicketingRequestLimit = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.listTicketingRequestLimit, { crm_id })
  );

export const editTicketingRequestLimit = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTicketingRequestLimit, { input })
  );

export const linkAppUserToProfilingNode = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkAppUserToProfilingNode, { input })
  );

export const getLinkedAppUserToProfilingNode = async node_id =>
  await API.graphql(
    graphqlOperation(queries.getLinkedAppUserToProfilingNode, {
      node_id
    })
  );

export const unlinkAppUserToProfilingNode = async input =>
  await API.graphql(
    graphqlOperation(mutations.unlinkAppUserToProfilingNode, { input })
  );

export const listEvasionLimitSlots = async dates_to_check =>
  await API.graphql(
    graphqlOperation(queries.listEvasionLimitSlots, { dates_to_check })
  );

export const listComuniProvince = async is_prov =>
  await API.graphql(
    graphqlOperation(queries.listComuniProvince, { is_prov })
  );

export const listDocumentMeteoTypes = async type =>
  await API.graphql(
    graphqlOperation(queries.listDocumentMeteoTypes, { type })
  );

export const listFilteredTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(queries.listFilteredTicketingRequest, { input })
  );

export const listProfilingAppUser = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.listProfilingAppUser, { crm_id })
  );

export const editEvasionLimitSlots = async input =>
  await API.graphql(
    graphqlOperation(mutations.editEvasionLimitSlots, { input })
  );

export const getTicketingRequestComplete = async input =>
  await API.graphql(
    graphqlOperation(queries.getTicketingRequestComplete, { input })
  );

export const createTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.createTicketingRequest, { input })
  );

export const linkLocationToTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkLocationToTicketingRequest, { input })
  );

export const deleteTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteTicketingRequest, { input })
  );

export const linkDocumentS3ToTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkDocumentS3ToTicketingRequest, { input })
  );

export const getLinkedDocumentS3toTicketingRequest = async request_id =>
  await API.graphql(
    graphqlOperation(queries.getLinkedDocumentS3toTicketingRequest, {
      request_id
    })
  );

export const deleteDocumentS3 = async document_s3_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteDocumentS3, {
      document_s3_id
    })
  );

export const editTicketingRequestState = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTicketingRequestState, { input })
  );

export const deleteLinkedLocationToTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteLinkedLocationToTicketingRequest, { input })
  );

export const editTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTicketingRequest, { input })
  );

export const getEnabledServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.listEnabledServicesPolicy, {
      input
    })
  );

export const deleteDocumentalUrlNode = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteDocumentalUrlNode, { input })
  );
