<template>
  <v-app>
    <div>
      <toast position="ne" />
    </div>
    <div
      v-if="authState !== 'signedin'"
      name="auth-container"
    >
      <v-row class="d-block">
        <v-row>
          <v-spacer />
          <v-col class="d-block text-center">
            <v-img
              src="@/assets/logo.png"
              width="10em"
              class="d-inline-block"
              fixed
            />
          </v-col>
          <v-spacer />
        </v-row>
        <v-col class="height-login d-flex">
          <v-spacer />
          <amplify-authenticator
            :auth-config="{ signInConfig: { isSignUpDisplayed: false } }"
          >
            <amplify-sign-in
              slot="sign-in"
              class="custom-sign-in"
              header-text="Sign In"
              hide-sign-up="true"
              :form-fields.prop="formFields"
            />
          </amplify-authenticator>
          <v-spacer />
        </v-col>
      </v-row>
    </div>

    <div v-if="authState === 'signedin' && user">
      <core-app-bar />
      <core-drawer />
      <core-view />
    </div>
  </v-app>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { mapActions } from 'vuex';
import { Toast } from 'vuex-toast';
import * as api from '@/api/api.js';
import Holidays from 'date-holidays';
import moment from 'moment-business-days';

export default {
  name: 'App',

  components: {
    CoreDrawer: () => import('@/components/core/Drawer'),
    CoreAppBar: () => import('@/components/core/AppBar'),
    CoreView: () => import('@/components/core/View'),
    Toast
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      formFields: [
        {
          type: 'username',
          label: 'Username',
          placeholder: 'Write your username',
          required: true
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: 'Write your password',
          required: true
        }
      ]
    };
  },
  watch: {
    $route (to, from) {
      if (from.path.substring(1) === 'layer-translation') {
        this.layers.isCustomerLayerTranslating = false;
      }
    }
  },
  async created() {
    onAuthUIStateChange(async (authState, authData) => {
      this.authState = authState;
      this.user = authData;

      if (this.user) {
        if (this.user.challengeName != 'NEW_PASSWORD_REQUIRED') {
          if (this.user.signInUserSession) {
            if (this.user.signInUserSession.idToken) {
              if (
                this.user.signInUserSession.idToken.payload[
                  'cognito:groups'
                ][0] == 'admin'
              ) {
                await this.storeUsername(authData.username);
                await this.setCredentials();
                await this.refreshToken();
                this.checkHolidays();
              } else {
                api.logOff();
                this.$router.push('/');
                location.reload();
              }
            } else {
              api.logOff();
              this.$router.push('/');
              location.reload();
            }
          } else {
            api.logOff();
            this.$router.push('/');
            location.reload();
          }

          await this.storeServices();
        }
      }
    });
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
  methods: {
    ...mapActions(['storeUsername', 'setCredentials', 'refreshToken', 'storeServices', 'layers']),
    checkHolidays() {
      const thisYear = moment().format('YYYY');
      const checkThisYearHolidays = new Holidays('IT').getHolidays(thisYear);
      const thisYearHolidays = checkThisYearHolidays.map(day => day.date.split(' ')[0]);
      const nextYear = moment().add(1, 'years').format('YYYY');
      const checkNextYearHolidays = new Holidays('IT').getHolidays(nextYear);
      const nextYearHolidays = checkNextYearHolidays.map(day => day.date.split(' ')[0]);
      moment.updateLocale('it', {
        holidays: thisYearHolidays.concat(nextYearHolidays),
        holidayFormat: 'YYYY-MM-DD',
        workingWeekdays: [1, 2, 3, 4, 5],
        nextBusinessDayLimit: 31,
        months: [
          'January', 'February', 'March', 'April', 'May', 'June', 'July',
          'August', 'September', 'October', 'November', 'December'
        ]
      });
    }
  }
};
</script>

<style lang="scss">
:root {
  --amplify-primary-color: #0ca344;
  --amplify-primary-tint: #0ca344;
  --amplify-primary-shade: #0ca344;
}

.custom-sign-in {
  display: block;
  position: absolute;
  top: 10em;
}

.height-login {
  height: 30em;
}

.amplify-authenticator {
  height: 35em;
  min-height: 35em;
  max-height: 35em;
  display: block;
}

.full-height-class {
  height: 100%;
}

.no-flex-wrap {
  flex-wrap: none;
}

/* change scrollbar style */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: #eeeeee;
}
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
}
</style>
