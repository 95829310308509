// recursive function for create linear array of father/children from v-treeview hierarchy regardless of the hierarchy level
export const findItemNested = (arr, nestingKey, pushingArr, routeType) =>
  arr.reduce((a, item) => {
    if (a) {
      return a;
    }

    if (item[nestingKey]) {
      const returnItem = {
        service_id: item.service_id,
        node_id: item.node_id,
        node_name: item.node_name,
        node_description: item.node_description || '',
        father_id: item.father_id,
        sort_id: item.sort_id.toString()
      };

      if (routeType === 'URL') {
        returnItem.external_link = item.external_link || '';
      }

      if (routeType === 'DOC') {
        returnItem.path_s3_bucket = item.path_s3_bucket || '';
      }

      return (
        pushingArr.push(returnItem),
        pushingArr.sort((a, b) => a.sort_id - b.sort_id),
        findItemNested(item[nestingKey], nestingKey, pushingArr, routeType)
      );
    }
  }, null);

//generate nested array from linear Object array. 1st check parent/child id match with filter
//2nd pass item shallow copy + children array to new Obj array with map
export const nestHierarchyItems = (linearObjectArray, node_id = '') => {
  return linearObjectArray
    .filter(item => item.father_id == node_id)
    .map(item => ({
      ...item,
      children: nestHierarchyItems(linearObjectArray, item.node_id)
    }));
};

// recursive function for delete by id the children from the v-treeview hierarchy regardless of the hierarchy level
export const deleteItemNested = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) {
      return a;
    }

    if (item.node_id === itemId) {
      return arr.splice(arr.indexOf(item), 1);
    }

    if (item[nestingKey]) {
      return deleteItemNested(item[nestingKey], itemId, nestingKey);
    }
  }, null);

// recursive function for edit by id the children from the v-treeview hierarchy regardless of the hierarchy level
export const editItemNested = (arr, itemId, nestingKey, childrenForm) =>
  arr.reduce((a, item) => {
    if (a) {
      return a;
    }

    if (item.node_id === itemId) {
      return [
        (item.node_name = childrenForm.node_name),
        (item.node_description = childrenForm.node_description),
        (item.external_link = childrenForm.external_link),
        (item.path_s3_bucket = childrenForm.path_s3_bucket)
      ];
    }

    if (item[nestingKey]) {
      return editItemNested(item[nestingKey], itemId, nestingKey, childrenForm);
    }
  }, null);

export const modifiedHierarchyItemsOnly = (linearObjectArray, existingConfigurationArray) => {
  return linearObjectArray.filter(
    edited => {
      return !existingConfigurationArray.some(
        notEdited => {
          return (
            Object.entries(edited)
              .sort()
              .toString() ===
            Object.entries(notEdited)
              .sort()
              .toString()
          );
        }
      );
    }
  );
};

export const setOldNameToEditedItems = (modifiedObjectArray, existingConfigurationArray) => {
  return modifiedObjectArray.filter(edited => {
    return existingConfigurationArray.map(notEdited => {
      if (notEdited.node_id == edited.node_id) {
        Object.assign(edited, {
          node_old_name: notEdited.node_name
        });

        return edited;
      }
    });
  });
};

export const deletedHierarchyItemsOnly = (linearObjectArray, existingConfigurationArray) => {
  return existingConfigurationArray.filter(
    notDeleted => {
      return !linearObjectArray.some(deleted => {
        return deleted.node_id === notDeleted.node_id;
      });
    }
  );
};

export const createdHierarchyItemsOnly = (modifiedObjectArray, editedOnlyPushingArray, createdOnlyPushingArray) => {
  return modifiedObjectArray.filter(edited => {
    if (edited.node_old_name != undefined) {
      editedOnlyPushingArray.push(edited);
    } else {
      createdOnlyPushingArray.push(edited);
    }
  });
};
