import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@aws-amplify/ui-vue';
import { currentConfig } from './api/api.js';
import 'vuex-toast/dist/vuex-toast.css';
import moment from 'moment-business-days';

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  currentConfig,
  render: h => h(App)
}).$mount('#app');
