import {
  getDomains,
  newDomain,
  updateDomain,
  deleteDomain,
  checkUserDomain
} from '@/api/api.js';

export const state = {
  headers: [
    {
      text: 'Domain ID',
      align: 'start',
      sortable: false,
      value: 'domain_id'
    },
    { text: 'Domain Name', value: 'domain_name' },
    { text: 'Domain Description', value: 'domain_description' },
    { text: 'Actions', value: 'actions', sortable: false, width: 130 }
  ],
  domains: [],
  blankDomain: {
    domain_id: 0,
    domain_name: '',
    domain_description: '',
    polygon: ''
  },
  geoJSON: {},
  BBox: null,
  polyString: '',
  //  "MULTIPOLYGON(((8.470142388178242 45.738857963675905,7.328484004083945 40.58279732007195,11.14877052390634 40.70463479849297,12.014418939539484 43.89206385234529,8.470142388178242 45.738857963675905)))",
  isModifingDomain: false
};

export const mutations = {
  DOMAIN_DETAILS(state, domainDetails) {
    state.blankDomain.domain_id = domainDetails.domain_id;
    state.blankDomain.domain_name = domainDetails.domain_name;
    state.blankDomain.domain_description = domainDetails.domain_description;
  },

  DOMAIN_POLYGON(state, domainPolygon) {
    state.blankDomain.polygon = domainPolygon;
    state.polyString = domainPolygon;
  },

  STORE_MODIFY_POLYGON(state, domain_id) {
    state.domains.forEach(elem => {
      if (elem.domain_id == domain_id) {
        state.polyString = elem.polygon;
      }
    });
  },

  GET_DOMAINS(state, domainsArray) {
    state.domains = domainsArray;
  },

  NEW_DOMAIN(state, domainToAdd) {
    state.domains.push(domainToAdd);
  },

  UPDATE_DOMAIN(state, domainToUpdate) {
    state.domains.forEach(element => {
      if (element.domain_id == domainToUpdate.data.updateDomain.domain_id) {
        element.domain_name = domainToUpdate.data.updateDomain.domain_name;
        element.domain_description =
          domainToUpdate.data.updateDomain.domain_description;
        element.polygon = domainToUpdate.data.updateDomain.polygon;
      }
    });
  },

  DELETE_DOMAIN(state, delete_domain_id) {
    state.domains.forEach(element => {
      if (element.domain_id == delete_domain_id) {
        const index = state.domains.indexOf(element);
        state.domains.splice(index, 1);
      }
    });
  }
};

export const actions = {
  storeDomainDetails({ commit }, domainDetails) {
    commit('DOMAIN_DETAILS', domainDetails);
  },

  storeDomainPolygon({ commit }, domainPolygon) {
    commit('DOMAIN_POLYGON', domainPolygon);
  },

  storeModifyPolygon({ commit }, domain_id) {
    commit('STORE_MODIFY_POLYGON', domain_id);
  },

  async storeDomains({ dispatch, commit }) {
    try {
      const dbDomains = await getDomains();
      commit('GET_DOMAINS', dbDomains.data.listDomains);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDomains',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewDomain({ state, dispatch, commit }) {
    try {
      const resp = await newDomain(
        state.blankDomain.domain_id,
        state.blankDomain.domain_name,
        state.blankDomain.domain_description,
        state.blankDomain.polygon
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'domain created',
          type: 'success'
        },
        { root: true }
      );
      resp.data.createDomain.polygon = state.blankDomain.polygon;
      commit('NEW_DOMAIN', resp.data.createDomain);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewDomain',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeUpdatedDomain({ dispatch, commit }) {
    try {
      const domainToUpdate = await updateDomain(
        state.blankDomain.domain_id,
        state.blankDomain.domain_name,
        state.blankDomain.domain_description,
        state.blankDomain.polygon
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'domani updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_DOMAIN', domainToUpdate);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedDomain',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteTableDomain({ dispatch, commit }, domain_id) {
    try {
      const check = await checkUserDomain(domain_id);

      if (check.data.checkUserDomain.result) {
        dispatch(
          'storeToastMessage',
          {
            text: 'You can\'t delete a domain attached to a Policy',
            type: 'warning'
          },
          { root: true }
        );
      } else {
        const domainToDelete = await deleteDomain(domain_id);
        dispatch(
          'storeToastMessage',
          {
            text: 'domain deleted',
            type: 'success'
          },
          { root: true }
        );
        commit('DELETE_DOMAIN', domainToDelete.data.deleteDomain.domain_id);
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteTableDomain',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
